import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import notify from 'devextreme/ui/notify';
import * as $ from 'jquery';

import { AuthenticationService } from '../services/authentication.service';
import { RemoteService } from '../services/remote.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  collapsed = true;
  isUserdataVisible = false;
  user: any;

  roles: any[];
  isAdmin = false;
  adminAccessData = { password: null };
  adminAccessVisible = false;
  passwordEditorInstance: any;

  constructor(
    private auth: AuthenticationService,
    private router: Router,
    private http: HttpClient,
    @Inject('API_URL') private serverUrl: string,
    private remote: RemoteService
  ) {
    window['$'] = $;
    window['jQuery'] = $;

    this.onPasswordInitialized = this.onPasswordInitialized.bind(this);
  }

  ngOnInit() {
    this.remote.getRequest('Users/GetUserData/').then((u) => (this.user = u));

    this.remote.getRequest('Users/GetAuthorizedRoles/').then((rr) => {
      this.roles = rr;
      this.isAdmin = rr.some((r) => r.RoleId == 'ADMIN');
    });
  }

  logout() {
    this.auth.logout();
  }

  userData() {
    this.isUserdataVisible = true;
  }

  closeUserdata() {
    this.isUserdataVisible = false;
  }

  expandCollapse() {
    this.collapsed = !this.collapsed;
  }

  admin() {
    this.adminAccessVisible = true;
  }

  async checkAdminAccess() {
    // Try to get a token
    const username = this.auth.getUser();
    const password = this.adminAccessData.password;
    const body = `grant_type=password&username=${username}&password=${password}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    console.log(username);
    try {
      const response = await this.http.post<any>(this.serverUrl + 'token', body, { headers: headers }).toPromise();
      if (response) this.router.navigate(['/admin']);
      else notify('Unauthorized', 'error');
    } catch {
      notify('Unauthorized', 'error');
    }

    this.adminAccessData.password = null;
    this.adminAccessVisible = false;
  }

  onPasswordInitialized(e) {
    this.passwordEditorInstance = e.component;
  }

  setFocusPassword() {
    setTimeout(() => this.passwordEditorInstance.focus(), 0);
  }
}
