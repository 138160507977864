import { Component, OnInit } from '@angular/core';

import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';

import { HttpCodeService } from 'src/app/services/http/http-code.service';
import { BaseSubscriptionComponent } from 'src/app/base/base.suscription.component';
import { lastValueFrom, takeUntil } from 'rxjs';
import { setUpdateValues } from 'src/app/helper/common-helper';
import { TreatmentType } from 'src/app/models/view-models/treatment-type';

@Component({
  selector: 'app-treatment-types',
  templateUrl: './treatment-types.component.html',
})
export class TreatmentTypesComponent extends BaseSubscriptionComponent implements OnInit {
  public treatmentTypes: DataSource;

  constructor(private httpCodesService: HttpCodeService) {
    super();
  }

  public ngOnInit(): void {
    this.treatmentTypes = this.treatmentTypesDatasource();
  }

  private treatmentTypesDatasource(): DataSource {
    return new DataSource({
      store: new CustomStore({
        key: 'Id',
        load: () => lastValueFrom(this.httpCodesService.getTreatmentTypes().pipe(takeUntil(this.destroyed))),
        update: (id, values) => {
          const toUpdate = this.treatmentTypes.items().find((_) => _.Id == id) as TreatmentType;

          setUpdateValues(values, toUpdate);

          return lastValueFrom(this.httpCodesService.updateTreatmentType(toUpdate).pipe(takeUntil(this.destroyed)));
        },
        remove: (id) => lastValueFrom(this.httpCodesService.deleteTreatmentType(id).pipe(takeUntil(this.destroyed))),
        insert: (treatmentType) =>
          lastValueFrom(this.httpCodesService.insertTreatmentType(treatmentType).pipe(takeUntil(this.destroyed))),
      }),
    });
  }
}
