import { Component, OnInit } from '@angular/core';

import notify from 'devextreme/ui/notify';
import { takeUntil } from 'rxjs';
import { BaseSubscriptionComponent } from 'src/app/base/base.suscription.component';

import { ConfigService } from 'src/app/services/config.service';
import { HttpCodeService } from 'src/app/services/http/http-code.service';
import { RemoteService } from 'src/app/services/remote.service';

@Component({
  selector: 'app-hotel-configuration',
  templateUrl: './hotel-configuration.component.html',
})
export class HotelConfigurationComponent extends BaseSubscriptionComponent implements OnInit {
  hotel: any;
  timezones: any[];

  constructor(
    private httpCodesService: HttpCodeService,
    private remote: RemoteService,
    private config: ConfigService
  ) {
    super();
  }

  ngOnInit() {
    this.hotel = this.config.getCurrentHotel();

    this.httpCodesService
      .getTimezones()
      .pipe(takeUntil(this.destroyed))
      .subscribe({
        next: (timezones) => (this.timezones = timezones),
        error: (e) => console.error(e),
      });
  }

  async save() {
    const url = 'Hotels/Update';
    this.remote
      .postRequest(url, this.hotel)
      .then(() => {
        this.config.setCurrentHotel(this.hotel);
        notify('Hotel data updated', 'info');
      })
      .catch(() => notify('Error updating the hotel', 'error'));
  }
}
