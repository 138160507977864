import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, isDevMode } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';

// Third Party
import { DevExtremeModule } from 'devextreme-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// Nuestros componentes
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { LoginComponent } from './login/login.component';

import { ROUTES } from './app.routes';

import { AuthenticationService } from './services/authentication.service';
import { RemoteService } from './services/remote.service';
import { CommonService } from './services/common.service';
import { SharedDataService } from './services/shared-data.service';
import { environment } from '../environments/environment';
import { AdminComponent } from './admin/admin.component';
import { InboxComponent } from './inbox/inbox.component';
import { TicketStatusesComponent } from './admin/ticket-statuses/ticket-statuses.component';
import { SubticketStatusesComponent } from './admin/subticket-statuses/subticket-statuses.component';
import { PrioritiesComponent } from './admin/priorities/priorities.component';
import { SubticketTypesComponent } from './admin/subticket-types/subticket-types.component';
import { SubticketTypeFieldsComponent } from './admin/subticket-types/subticket-type-fields/subticket-type-fields.component';
import { TemplatesComponent } from './admin/templates/templates.component';
import { TodoAssistComponent } from './todo-assist/todo-assist.component';
import { HotelConfigurationComponent } from './admin/hotel-configuration/hotel-configuration.component';
import { UserDataComponent } from './user-data/user-data.component';
import { UsersComponent } from './admin/users/users.component';
import { UserRolesComponent } from './admin/users/user-roles/user-roles.component';
import { GuestRequestsComponent } from './guest-requests/guest-requests.component';
import { HomeComponent } from './home/home.component';
import { VendorsComponent } from './admin/vendors/vendors.component';
import { VendorSubtickettypesComponent } from './admin/vendors/vendor-subtickettypes/vendor-subtickettypes.component';
import { ReplyGuestComponent } from './reply-guest/reply-guest.component';
import { SubticketComponent } from './subticket/subticket.component';
import { SafeurlPipe } from './pipes/safeurl.pipe';
import { MySubticketsComponent } from './my-subtickets/my-subtickets.component';
import { VendorDocumentsComponent } from './admin/vendors/vendor-documents/vendor-documents.component';
import { SearchSubticketTypeComponent } from './search-subticket-type/search-subticket-type.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportExecutionComponent } from './reports/report-execution/report-execution.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TelephoneBookComponent } from './telephone-book/telephone-book.component';
import { MyHandoverNotesComponent } from './my-handover-notes/my-handover-notes.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { SetHeaderInterceptor } from './interceptors/header-interceptor';
import { BaseSubscriptionComponent } from './base/base.suscription.component';
import { GuestRequestsItineraryComponent } from './guest-requests/itinerary/guest-requests-itinerary.component';
import { LoadingInterceptor } from './interceptors/loading.interceptor';
import { LoaderService } from './services/loader.service';
import { TreatmentTypesComponent } from './admin/treatment-types/treatment-types.component';
import { TreatmentRoomsComponent } from './admin/treatment-rooms/treatment-rooms.component';
import { TherapistsComponent } from './admin/therapists/therapists.component';
import { ReservationsComponent } from './reservations/reservations.component';
import { ReservationDetailComponent } from './reservations/reservation-detail/reservation-detail.component';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LoginComponent,
    AdminComponent,
    InboxComponent,
    TicketStatusesComponent,
    SubticketStatusesComponent,
    PrioritiesComponent,
    SubticketTypesComponent,
    SubticketTypeFieldsComponent,
    TemplatesComponent,
    TodoAssistComponent,
    HotelConfigurationComponent,
    UserDataComponent,
    UsersComponent,
    UserRolesComponent,
    HomeComponent,
    GuestRequestsComponent,
    VendorsComponent,
    TelephoneBookComponent,
    VendorSubtickettypesComponent,
    ReplyGuestComponent,
    SubticketComponent,
    SafeurlPipe,
    MySubticketsComponent,
    GuestRequestsItineraryComponent,
    MyHandoverNotesComponent,
    BaseSubscriptionComponent,
    VendorDocumentsComponent,
    SearchSubticketTypeComponent,
    ReportsComponent,
    ReportExecutionComponent,
    DashboardComponent,
    TreatmentTypesComponent,
    TreatmentRoomsComponent,
    TherapistsComponent,
    ReservationsComponent,
    ReservationDetailComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(ROUTES, { useHash: true }),
    HttpClientModule,
    FormsModule,

    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),

    // Third party
    DevExtremeModule,
    NgbModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    AuthenticationService,
    RemoteService,
    CommonService,
    SharedDataService,
    { provide: 'API_URL', useValue: environment.hostUrl },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SetHeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
