import { Component, OnInit, ViewChild } from '@angular/core';

import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';

import { VendorSubtickettypesComponent } from './vendor-subtickettypes/vendor-subtickettypes.component';
import { VendorDocumentsComponent } from './vendor-documents/vendor-documents.component';

import { RemoteService } from '../../services/remote.service';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-vendors',
  templateUrl: './vendors.component.html',
  styleUrls: ['./vendors.component.scss'],
})
export class VendorsComponent implements OnInit {
  vendors: DataSource;
  hotelId: number;
  selectedId: number;

  saving = false;

  @ViewChild(VendorSubtickettypesComponent, { static: false })
  public vendorSubticketTypes: VendorSubtickettypesComponent;
  @ViewChild(VendorDocumentsComponent, { static: false }) public vendorDocuments: VendorDocumentsComponent;
  constructor(private remote: RemoteService, private config: ConfigService) {
    //this.subticketTypeChanged = this.subticketTypeChanged.bind(this)
  }

  async ngOnInit() {
    this.config.currentHotel.subscribe(async (h) => {
      if (h) {
        this.hotelId = h.Id;
        this.vendors = this.usersStore();
      }
    });
  }

  onEditorPreparing(e) {
    if (e.parentType == 'dataRow' && e.dataField == 'PasswordHash') {
      e.editorOptions.mode = 'password';
    }
  }

  onInitNewRow(e) {
    e.data.PasswordHash = null;
    e.data.Enabled = true;
  }

  usersStore() {
    return new DataSource({
      store: new CustomStore({
        key: 'Id',
        load: () => {
          const url = 'Vendors/GetVendorsPerHotel/' + this.hotelId;
          return this.remote.getRequest(url);
        },
        insert: (data) => {
          data.HotelId = this.hotelId;
          data.SubticketTypes = this.vendorSubticketTypes.vendorSubticketTypes;
          data.Documents = this.vendorDocuments.vendorDocuments;
          const url = 'Vendors/Insert';
          return this.remote.postRequest(url, data);
        },
        remove: (Id) => {
          const url = 'Vendors/Delete/' + Id;
          return this.remote.postRequest(url, null);
        },
        update: (Id, data) => {
          console.log(this.vendorDocuments.vendorDocuments);

          data.Id = Id;
          data.HotelId = this.hotelId;
          data.SubticketTypes = this.vendorSubticketTypes.vendorSubticketTypes;
          data.Documents = this.vendorDocuments.vendorDocuments;
          const url = 'Vendors/Update';
          return this.remote.postRequest(url, data);
        },
      }),
    });
  }
}
