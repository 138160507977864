import { Component, OnInit } from '@angular/core';

import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';

import { RemoteService } from '../services/remote.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
})
export class ReportsComponent implements OnInit {
  reports: DataSource;
  reportChanged: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private remote: RemoteService) {
    this.executeReport = this.executeReport.bind(this);
  }

  ngOnInit() {
    this.reportChanged.next(null);
    this.reports = this.reportsDatasource();
  }

  setEditedValue(e, cellInfo) {
    cellInfo.setValue(e.value);
  }

  executeReport(e) {
    e.component.selectRows([e.row.key], false);
    this.reportChanged.next(e.row.data);
  }

  reportSaved() {
    console.log('reload');
    this.reports.reload();
  }

  public reportsDatasource() {
    return new DataSource({
      store: new CustomStore({
        key: 'Id',
        load: () => this.remote.getRequest('Reports/GetReports/'),
        update: (id, r) => this.remote.postRequest('Reports/Update/' + id, r),
        remove: (id) => this.remote.postRequest('Reports/Delete/' + id, null),
        insert: (r) => this.remote.postRequest('Reports/Insert/', r),
      }),
    });
  }
}
