import { Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, lastValueFrom, takeUntil } from 'rxjs';

import { DxDataGridComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';

import { HttpSubticketService } from '../services/http/http-subticket.service';
import { BaseSubscriptionComponent } from '../base/base.suscription.component';
import { Subticket } from '../models/view-models/subticket';
import notify from 'devextreme/ui/notify';
import { SharedDataService } from '../services/shared-data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-guest-requests',
  templateUrl: './guest-requests.component.html',
})
export class GuestRequestsComponent extends BaseSubscriptionComponent implements OnInit {
  public subtickets: DataSource;
  public subticketHistory: DataSource;
  public textFilter = '';
  public subticketDetailVisible = false;
  public subticketHistoryVisible = false;

  public subticketChanged = new BehaviorSubject(null);
  public modeChanged = new BehaviorSubject('view');

  @ViewChild(DxDataGridComponent, { static: false }) private subticketsGrid: DxDataGridComponent;

  constructor(
    private httpSubticketService: HttpSubticketService,
    private sharedDataService: SharedDataService,
    private router: Router
  ) {
    super();
  }

  public ngOnInit(): void {
    this.subtickets = this.subticketsDatasource();
  }

  public searchText(text: string): void {
    this.textFilter = text;
    this.refreshGrid();
  }

  public showSubticketHistory(subticket: Subticket): void {
    if (subticket) {
      this.subticketHistory = this.subticketHistoryDatasource(subticket.Id);
      this.subticketHistoryVisible = true;
    }
  }

  public showSubticketDetails(subticket: Subticket): void {
    this.modeChanged.next('view');
    this.subticketChanged.next(subticket);
    this.subticketDetailVisible = true;
  }

  public subticketComponentClose(): void {
    this.subticketDetailVisible = false;
  }

  public subticketSaved(): void {
    this.subticketDetailVisible = false;
    this.subticketsGrid.instance.refresh();
  }

  public printItinerary(): void {
    const subtickets = this.subticketsGrid.instance.getSelectedRowsData() as Subticket[];
    if (subtickets.length == 0) {
      notify('Please, select some requests');
      return;
    }

    this.sharedDataService.sharedData.next(subtickets.map((_) => _.Id));
    this.router.navigate(['/guest-requests-itinerary']);
  }

  private refreshGrid(): void {
    this.subticketsGrid.instance.refresh();
  }

  private subticketsDatasource(): DataSource {
    return new DataSource({
      store: new CustomStore({
        key: 'Id',
        load: () =>
          lastValueFrom(
            this.httpSubticketService.getSubticketsFilteredByText(this.textFilter).pipe(takeUntil(this.destroyed))
          ),
      }),
    });
  }

  private subticketHistoryDatasource(subticketId: number): DataSource {
    return new DataSource({
      store: new CustomStore({
        key: 'Id',
        load: () =>
          lastValueFrom(this.httpSubticketService.getSubticketHistory(subticketId).pipe(takeUntil(this.destroyed))),
      }),
    });
  }
}
