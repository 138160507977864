import { Enum } from './enum';

export enum SubticketStatusEnum {
  Open = 1,
  Confirmed = 2,
  Pending = 3,
  Enquiry = 4,
  OnHold = 5,
  Cancelled = 6,
  Waitlist = 7,
}

export class SubticketStatusCodeEnum extends Enum<string> {
  public static readonly Open = new Enum('OPEN');
  public static readonly Confirmed = new Enum('CONFIRMED');
  public static readonly Pending = new Enum('PENDING');
  public static readonly Enquiry = new Enum('ENQUIRY');
  public static readonly OnHold = new Enum('ONHOLD');
  public static readonly Cancelled = new Enum('CANCELLED');
  public static readonly Waitlist = new Enum('WAITLIST');
}
