import { Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, lastValueFrom, takeUntil } from 'rxjs';

import { DxDataGridComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';

import 'devextreme/integration/jquery';
import * as moment from 'moment';

import { SearchSubticketTypeComponent } from '../search-subticket-type/search-subticket-type.component';
import { HttpCodeService } from '../services/http/http-code.service';
import { BaseSubscriptionComponent } from '../base/base.suscription.component';
import { HttpSubticketService } from '../services/http/http-subticket.service';
import { Subticket } from '../models/view-models/subticket';
import { SubticketTypeEnum } from '../models/enums/subticket-type.enum';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent extends BaseSubscriptionComponent implements OnInit {
  public subtickets: DataSource;
  private subticketTypes: any[];
  public currentDate = moment().toDate();
  public subticketDetailVisible = false;

  public subticketChanged = new BehaviorSubject(null);
  public modeChanged = new BehaviorSubject('view');

  private subticketTypeSelected: any;
  private toolbar: any;

  @ViewChild(SearchSubticketTypeComponent, { static: false }) public search: SearchSubticketTypeComponent;

  @ViewChild(DxDataGridComponent, { static: false }) private subticketsGrid: DxDataGridComponent;

  constructor(private httpSubticketService: HttpSubticketService, private httpCodesService: HttpCodeService) {
    super();
  }

  public async ngOnInit(): Promise<void> {
    this.subtickets = this.subticketsDatasource();

    lastValueFrom(this.httpCodesService.getSubticketTypes().pipe(takeUntil(this.destroyed))).then((subticketTypes) => {
      this.subticketTypes = subticketTypes.filter((_) => _.Id != SubticketTypeEnum.Spa);

      // Set default Selected
      this.subticketTypeSelected = this.subticketTypes[0];
    });
  }

  private refreshGrid(): void {
    this.subticketsGrid.instance.refresh();
  }

  public subticketComponentClose(): void {
    this.subticketDetailVisible = false;
  }

  public onToolbarPreparing(e): void {
    this.toolbar = e.component;
    e.toolbarOptions.items.unshift({
      location: 'before',
      template: (t) => {
        return "<div style='font-size: 16px; margin-left: 18vw'><b>" + t.options.text + '</b></div>';
      },
      widget: 'dxTextBox',
      options: {
        text: moment().format('dddd DD MMMM YYYY'),
        readOnly: true,
      },
    });
  }

  public onContentReady(e): void {
    this.toolbar = $(e.element).find('.dx-datagrid-header-panel .dx-toolbar').dxToolbar('instance');
  }

  public dateChanged(e): void {
    this.currentDate = e.value;
    this.toolbar.option('items[0].options.text', moment(e.value).format('dddd DD MMMM YYYY'));
    this.refreshGrid();
  }

  public selectSubticketType(subticketType): void {
    this.subticketTypeSelected = subticketType;
  }

  public newGuestRequest(): void {
    const subticket = new Subticket();
    subticket.SubticketType = this.subticketTypes.find((st) => st.Id == this.subticketTypeSelected.Id);
    subticket.SourceText = 'Request at Reception Desk';

    this.modeChanged.next('create');
    this.subticketChanged.next(subticket);
    this.subticketDetailVisible = true;
  }

  public showSubticketDetails(subticket: Subticket): void {
    this.modeChanged.next('view');
    this.subticketChanged.next(subticket);
    this.subticketDetailVisible = true;
  }

  public subticketSaved(): void {
    this.subticketDetailVisible = false;
    this.subticketsGrid.instance.refresh();
  }

  private subticketsDatasource(): DataSource {
    return new DataSource({
      store: new CustomStore({
        key: 'Id',
        load: () =>
          lastValueFrom(
            this.httpSubticketService
              .getSubticketsFilteredByDate(moment(this.currentDate).format('YYYY-MM-DD'))
              .pipe(takeUntil(this.destroyed))
          ),
      }),
    });
  }
}
