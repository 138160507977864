import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpUserService {
  private baseUrl = `${this.serverUrl}Users`;

  constructor(private httpClient: HttpClient, @Inject('API_URL') public serverUrl: string) {}

  public getAgents(): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.baseUrl}/GetAgents/`);
  }
}
