import { Enum } from './enum';

export enum TicketStatusEnum {
  New = 1,
  InProgress = 2,
  Answerdue = 3,
  Closed = 4,
  ReadyToClose = 5,
  Cancelled = 6,
}

export class TicketStatusCodeEnum extends Enum<string> {
  public static readonly New = new Enum('NEW');
  public static readonly InProgress = new Enum('INPROGRESS');
  public static readonly Answerdue = new Enum('ANSWERDUE');
  public static readonly Closed = new Enum('CLOSED');
  public static readonly ReadyToClose = new Enum('READYTOCLOSE');
  public static readonly Cancelled = new Enum('CANCELLED');
}
