import { Component, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';

@Component({
  template: ``,
})
export class BaseSubscriptionComponent implements OnDestroy {
  protected destroyed = new Subject<void>();

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
