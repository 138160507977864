import { Nullable } from '../view-models/nullable-type';
import { Subticket } from '../view-models/subticket';

export class CreateUpdateSubticket {
  public TicketId: Nullable<number>;
  public Subticket: CreateUpdateSubticketSubticket;
  public SubticketInfo: any;
}

export class CreateUpdateSubticketTicket {
  public HotelId: number;
}

export class CreateUpdateSubticketSubticket {
  public TicketId: Nullable<number>;
  public SubticketTypeId: number;
  public SourceText: string;
  public GuestName: Nullable<string>;
  public NumberOfGuests: Nullable<number>;
  public DueDate: Date;
  public Notes: Nullable<string>;
  public SubticketStatusId: number;
  public AsigneeId: Nullable<string>;
  public VendorId: Nullable<number>;

  constructor(subticket: Subticket) {
    this.TicketId = subticket.TicketId;
    this.SubticketTypeId = subticket.SubticketType.Id;
    this.GuestName = subticket.GuestName;
    this.DueDate = subticket.DueDate;
    this.Notes = subticket.Notes;
    this.SubticketStatusId = subticket.SubticketStatus.Id;
    this.AsigneeId = subticket.AsigneeId;
    this.VendorId = subticket.VendorId;
    this.NumberOfGuests = subticket.NumberOfGuests;
    this.SourceText = subticket.SourceText ? subticket.SourceText : 'Manual Creation';
  }
}
