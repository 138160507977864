import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import notify from 'devextreme/ui/notify';

import { RemoteService } from '../services/remote.service';
import { SharedDataService } from '../services/shared-data.service';
import { HtmlEditor_Toolbar_Items } from '../base/constants';
import { HttpSubticketService } from '../services/http/http-subticket.service';
import { BaseSubscriptionComponent } from '../base/base.suscription.component';
import { lastValueFrom, takeUntil } from 'rxjs';

@Component({
  selector: 'app-reply-guest',
  templateUrl: './reply-guest.component.html',
})
export class ReplyGuestComponent extends BaseSubscriptionComponent implements OnInit {
  ticket: any;
  subtickets = [];
  selectedSubtickets = [];

  replyEditor: any;
  replyContents = '';
  replyEditorCursorPos: number;
  replyToolbar = HtmlEditor_Toolbar_Items;

  requestEditor: any;
  requestContents = '';

  sendingReply = false;

  constructor(
    private shared: SharedDataService,
    private remote: RemoteService,
    private router: Router,
    private httpSubticketService: HttpSubticketService
  ) {
    super();
  }

  public async ngOnInit(): Promise<void> {
    this.ticket = this.shared.storage;

    await lastValueFrom(
      this.httpSubticketService.getSubticketTemplates(this.ticket.Id).pipe(takeUntil(this.destroyed))
    ).then((templates) => {
      this.subtickets = templates;
      this.selectedSubtickets = this.subtickets.filter((s) => s.TemplateIsDefault);
    });

    // Generate automatic answer only if the previous reply is empty
    if (this.ticket.Reply == null) {
      this.replyContents = await this.getReplyText(this.selectedSubtickets);
    } else {
      this.replyContents = this.ticket.Reply;
    }

    this.requestContents = this.ticket ? this.ticket.Message : null;
  }

  public editorInitialized(e) {
    switch (e.element.id) {
      case 'replyeditor':
        this.replyEditor = e.component;
        this.replyEditor.getQuillInstance().on('selection-change', (range, oldRange, source) => {
          if (range) this.replyEditorCursorPos = range.index;
        });
        break;
      case 'requesteditor':
        this.requestEditor = e.component;
        break;
    }
  }

  subticketsReordered(e) {
    const aux = this.subtickets[e.toIndex];
    this.subtickets[e.toIndex] = this.subtickets[e.fromIndex];
    this.subtickets[e.fromIndex] = aux;

    // Reorder selected subtickets
    const newSelectedSubtickets = [];
    this.subtickets.forEach((s) => {
      if (this.selectedSubtickets.includes(s)) newSelectedSubtickets.push(s);
    });

    this.selectedSubtickets = newSelectedSubtickets;
  }

  public async generateReply(): Promise<void> {
    this.replyContents = await this.getReplyText(this.selectedSubtickets);
  }

  public async insertTemplate(subticket): Promise<void> {
    await lastValueFrom(
      this.httpSubticketService.generateReplyOrItineraryText([subticket]).pipe(takeUntil(this.destroyed))
    ).then((replyText) => {
      const text = replyText;
      this.replyContents = this.replyContents + '<br>' + text;
    });
  }

  private async getReplyText(subtickets): Promise<string> {
    return await lastValueFrom(
      this.httpSubticketService.generateReplyOrItineraryText(subtickets).pipe(takeUntil(this.destroyed))
    );
  }

  onReplyChanged(e) {
    this.replyContents = e.value;
  }

  sendReply() {
    this.sendingReply = true;
    const messageObject = {
      toAddress: this.ticket.From,
      message: this.replyContents,
    };

    this.remote
      .postRequest('Tickets/SendReply/' + this.ticket.Id, messageObject)
      .then((r) => notify('Reply sent to guest'))
      .catch((r) => notify(r, 'error'));

    this.sendingReply = false;
  }

  saveAndBack() {
    const url = 'Tickets/SaveReply/' + this.ticket.Id;
    this.remote
      .postRequest(url, this.replyContents)
      .then((r) => {
        notify('Reply saved');
        this.router.navigate(['/inbox']);
      })
      .catch((e) => {
        notify(e, 'error');
      });
  }
}
