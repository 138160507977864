import { AfterViewChecked, ChangeDetectorRef, Component } from '@angular/core';
import { AuthenticationService } from './services/authentication.service';
import { BehaviorSubject } from 'rxjs';
import { LoaderService } from './services/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewChecked {
  public isLoading: BehaviorSubject<boolean> = this.loaderService.isLoading;
  public loggedIn = false;

  constructor(
    auth: AuthenticationService,
    private loaderService: LoaderService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    auth.authenticatedChanged.subscribe((e) => (this.loggedIn = e));
  }

  public ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }
}
