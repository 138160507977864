import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';

import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { DxDataGridComponent } from 'devextreme-angular'

import 'devextreme/integration/jquery';
import notify from 'devextreme/ui/notify'

import { RemoteService } from '../../services/remote.service'
import { BehaviorSubject } from 'rxjs';
import { Column } from 'devextreme/ui/data_grid';
import { Field } from 'devextreme/ui/filter_builder';


import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { jsPDF } from 'jspdf';

import * as dayjs from 'dayjs'
@Component({
    selector: 'app-report-execution',
    templateUrl: './report-execution.component.html',
    styleUrls: ['./report-execution.component.scss']
})
export class ReportExecutionComponent implements OnInit {

    report: any
    ticketsInfo: DataSource

    filters = []
    filterFields: Array<Field> = []
    customOperations = []
    isFilterVisible = false
    fields = []

    toolbar: any

    @ViewChild(DxDataGridComponent, { static: false }) ticketsInfoGrid: DxDataGridComponent

    @Input() reportChanged: BehaviorSubject<any>
    @Output() onReportSaved = new EventEmitter();

    constructor(private remote: RemoteService) {
        this.customizeColumns = this.customizeColumns.bind(this)
    }

    async ngOnInit() {
        this.customOperations = [{
            name: "daysago",
            caption: "After Days Ago",
            dataTypes: ["date", "datetime"],
            icon: "fa fa-calendar",
            editorTemplate: "numberBoxTemplate",
            hasValue: true,
            customizeText: (f) => f.value
        },
        {
            name: "todaysago",
            caption: "Before Days Ago",
            dataTypes: ["date", "datetime"],
            icon: "fa fa-calendar",
            editorTemplate: "numberBoxTemplate",
            hasValue: true,
            customizeText: (f) => f.value
        }
        ]

        this.reportChanged.subscribe(async r => {
            if (r != null) {
                this.report = r
                this.fields = JSON.parse(r.Fields)
                this.filters = JSON.parse(r.Filters)
                this.filterFields = []
                this.ticketsInfo = this.ticketsInfoDatasource()
            }
        })
    }

    setFilterVisible() {
        this.isFilterVisible = true
    }

    customizeColumns(columns: Array<Column>) {
        this.filterFields = []

        columns.map(c => {
            c.visible = this.fields.includes(c.dataField)
            switch (c.dataField) {
                case 'TicketReceived':
                case 'TicketDueDate':
                case 'SubticketDueDate':
                case 'SubticketVendorNotifiedDate':
                    c.dataType = 'datetime';
                    c.format = 'dd-MM-yyyy HH:mm';
                    break;

                case 'NumberOfGuests':
                case 'Id':
                case 'TicketId':
                    c.dataType = 'number'
                    break;
            }

            // At the same time, add the filter fields
            let filterField: Field = {
                caption: c.caption,
                dataField: c.dataField,
                dataType: c.dataType,
            }
            this.filterFields.push(filterField)

            return c
        })

        // Use the apropiate order
        for (var column of columns) {
            column.visibleIndex = this.fields.indexOf(column.dataField)
        }

    }

    showFilterBuilder() {
        this.isFilterVisible = true
    }

    public async applyFilter() {
        let filters = await this.filters
        console.log(filters)
        this.isFilterVisible = false
        this.ticketsInfo.reload()
    }

    public async saveReportDefinition() {

        if (this.report) {

            // Get the current visible columns
            this.fields = []

            // FIXME a - b is an unsafe comparison implementation
            for (var column of this.ticketsInfoGrid.instance.getVisibleColumns().sort((a, b) => a.visibleIndex - b.visibleIndex)) {
                this.fields.push(column.dataField)
            }
            let a: number = 11
            let url = 'Reports/SaveDefinition/' + this.report.Id
            let data = { filters: JSON.stringify(this.filters), fields: JSON.stringify(this.fields) }
            await this.remote.postRequest(url, data)
            this.onReportSaved.emit()
            notify("Definition saved")
        }
    }

    ticketsInfoDatasource() {
        return new DataSource({
            store: new CustomStore({
                key: "Id",
                load: () => {
                    let url = 'Reports/GetTicketsAdvancedFilter'
                    let parameters = (this.filters == null || this.filters.length == 0) ? null : this.filters
                    return this.remote.postRequest(url, parameters)
                },
            })
        })
    }

    async print() {
        const doc = new jsPDF('l', 'mm', 'a4')

        await exportDataGridToPdf({
            jsPDFDocument: doc,
            margin: { top: 35, right: 20, bottom: 20, left: 20 },
            component: this.ticketsInfoGrid.instance
        })

        let hotel = await this.remote.getRequest('Hotels/GetHotelLinkedToUser')

        const header = hotel.Name;
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
        const headerWidth = doc.getTextDimensions(header).w;

        const subtitle = this.report.Name;
        const subtitleWidth = doc.getTextDimensions(subtitle).w;
        var pageCount = (doc as any).internal.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);

            doc.setTextColor('#000000');
            doc.setFontSize(15);
            doc.text(header, (pageWidth - headerWidth) / 2, 20);

            doc.setTextColor('#000000');
            doc.setFontSize(10);
            doc.text(subtitle, (pageWidth - subtitleWidth) / 2, 20 + 9);

            doc.setFontSize(9);
            doc.setTextColor('#888888');
            const footer = dayjs().format();
            const footerWidth = doc.getTextDimensions(footer).w;
            doc.text(footer, (pageWidth - footerWidth) - 40, pageHeight - 20);

        }

        let fileContents = doc.output('blob');
        const blob = new Blob([fileContents], { type: 'application/pdf' })
        const previewUrl = window.URL.createObjectURL(blob)
        window.open(previewUrl, '_blank');
    }
}
