import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core'

import notify from 'devextreme/ui/notify';
import 'devextreme/data/odata/store'
import DataSource from 'devextreme/data/data_source'
import { DxFormComponent } from 'devextreme-angular'

import { RemoteService } from '../services/remote.service';

@Component({
  selector: 'app-user-data',
  templateUrl: './user-data.component.html',
  styleUrls: ['./user-data.component.scss']
})
export class UserDataComponent implements OnInit {

    userName: string
    user: any
    languages: DataSource
    roles: DataSource

    newPasswordText: string
    repeatPasswordText: string
    nameText: string
    surnamesText: string
    emailText: string
    languageText: string
    roleText: string

    languageSelectOptions: any

    @ViewChild(DxFormComponent, {static: false}) private userForm: DxFormComponent
    @Output() onCloseUserdata = new EventEmitter();

    constructor(
        private remote: RemoteService
    ) { }

    async ngOnInit() {
        this.user = await this.remote.getRequest('Users/GetUserData/')

        this.user = {
            Id: this.user.Id,
            Name: this.user.Name,
            Email: this.user.Email,
            newPassword: null,
            repeatPassword: null,
        }

        this.newPassword = this.newPassword.bind(this)
    }

    newPassword() {
        return this.user.newPassword
    }

    // Poner un valor por defecto en las select box
    //async onInitialized(e) {
    //    let data = await e.component.getDataSource().load()
    //    e.component.option("value", data[0].Id);                    
    //}

    // Guardar los datos de detalle del usuario
    async save() {
        let validationResult = this.userForm.instance.validate()
        if (validationResult.isValid)
        {
            this.user.PasswordHash = this.user.newPassword
            let url = 'Users/update'
            await this.remote.postRequest(url, this.user)
            notify("User data updated")
            this.onCloseUserdata.emit()
        }
    }

}
