import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';

// TODO: Implement interceptor fot all app (**), remove RemoteService

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthenticationService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const userBearerToken = this.authService.getToken();

    // TODO: Temporal validation, view SetHeaderInterceptor.
    const skipThisInterceptor = req.headers.get('X-Skip-Auth-Interceptor');

    if ((skipThisInterceptor && skipThisInterceptor == 'true') || !userBearerToken) {
      return next.handle(req.clone());
    }

    const clonedReq = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${userBearerToken}`),
    });

    return next.handle(clonedReq).pipe(
      catchError((err) => {
        if (err && err.status == 401) {
          this.authService.logout();
        }

        return throwError(err);
      })
    );
  }
}
