import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { BehaviorSubject, lastValueFrom, takeUntil } from 'rxjs';
import notify from 'devextreme/ui/notify';

import * as moment from 'moment';

import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { Subticket } from '../models/view-models/subticket';
import { HttpSubticketService } from '../services/http/http-subticket.service';
import { BaseSubscriptionComponent } from '../base/base.suscription.component';
import { SubticketTypeCodeEnum } from '../models/enums/subticket-type.enum';
import { HandoverStatusCodeEnum } from '../models/enums/handover-note-status.enum';
import { CreateUpdateSubticket, CreateUpdateSubticketSubticket } from '../models/requests/create-update-subticket';
import { SubticketStatus } from '../models/view-models/subticket-status';
import { GeneratePdfItineraryBySubticket } from '../models/requests/generate-pdf-itinerary-by-subticket';
import { HttpCodeService } from '../services/http/http-code.service';
import { DxValidatorComponent } from 'devextreme-angular';
import { SubticketTypeFieldValue } from '../models/view-models/subticket-type-field-value';
import { SubticketFieldTypeConfig, SubticketFieldTypeEnum } from '../models/enums/subticket-field-type.enum';
import { DxiItem } from '../models/view-models/dxi-item';
import { SubticketStatusCodeEnum } from '../models/enums/subticket-status.enum';
import { HttpUserService } from '../services/http/http-user.service';
import { setCorrectDateTimeForBackend } from '../helper/common-helper';

@Component({
  selector: 'app-subticket',
  templateUrl: './subticket.component.html',
  styleUrls: ['./subticket.component.scss'],
})
export class SubticketComponent extends BaseSubscriptionComponent implements OnInit {
  public subticket: Subticket = new Subticket();
  public selectedAgentId: string;
  public dxiItems: DxiItem[] = [];
  public dxiItemsWithCustomTemplates: DxiItem[] = [];
  public subticketActions: any[];
  public subticketInfo: any;
  public agents: any[];
  public vendors: any[];
  public selectedVendorId: number;
  public selectedAction: any;
  public notifyVendorDateFormatted: string;
  public subticketHistory: DataSource;
  public subticketHistoryVisible = false;
  public isHandoverNote = false;
  public isDueDateValid = true;
  public dueDateError = {};
  public pdfLoading = false;
  public showPdfPreview = false;
  public previewUrl = null;

  // For File Field
  public fileMaxSizeByte = 5000000; // 5mb
  public allowedFileExtensions = ['.jpg', '.jpeg', '.png', '.pdf'];
  public allowedFileExtensionsMimeTypes = 'image/jpg,image/jpeg,image/png,application/pdf';

  private fileToUpload: File = null;
  private invalidFile = false;
  private fieldCodeForFile = '';
  private shouldDeletePreviosFile = false; // Only for Edition

  private mode: string;
  private allSubticketStatuses: SubticketStatus[];

  @Input() subticketChanged: BehaviorSubject<any>;
  @Input() modeChanged: BehaviorSubject<string>;
  @Input() ticketId: number; // optional. 0 if a new ticket has to be created
  @Output() saved = new EventEmitter();
  @Output() deleted = new EventEmitter();
  @Output() canceled = new EventEmitter();
  @Output() vendorNotified = new EventEmitter();

  @ViewChild('dynamicFieldsValidator', { static: false }) public dynamicFieldsValidator: DxValidatorComponent;

  constructor(
    private httpSubticketService: HttpSubticketService,
    private httpCodesService: HttpCodeService,
    private httpUserService: HttpUserService
  ) {
    super();
  }

  public async ngOnInit(): Promise<void> {
    this.modeChanged.pipe(takeUntil(this.destroyed)).subscribe((m) => (this.mode = m));

    await this.loadInitialData();

    this.subticketChanged.pipe(takeUntil(this.destroyed)).subscribe(async (s: Subticket) => {
      this.subticket = Object.assign(new Subticket(), s);
      this.isHandoverNote = this.subticket.SubticketType.Code === SubticketTypeCodeEnum.Handover.value;

      this.vendors = await lastValueFrom(
        this.httpCodesService.getVendors(this.subticket.SubticketType.Id).pipe(takeUntil(this.destroyed))
      );

      this.selectedAgentId = this.subticket.Asignee ? this.subticket.Asignee.Id : null;
      this.selectedVendorId = this.subticket.Vendor ? this.subticket.Vendor.Id : null;
      this.subticketInfo = {};

      // Subticket Creation
      if (this.mode == 'create') {
        await this.setDxiItemsForCreation();

        // Logic for Handovers
        if (this.isHandoverNote) {
          this.subticketActions = [this.allSubticketStatuses.find((ss) => ss.Code == HandoverStatusCodeEnum.New.value)];
          this.selectedVendorId = this.vendors[0].Id;
        } else {
          // Other Sub-Tickets
          this.subticketActions = [
            this.allSubticketStatuses.find((ss) => ss.Code == SubticketStatusCodeEnum.Open.value),
            this.allSubticketStatuses.find((ss) => ss.Code == SubticketStatusCodeEnum.Enquiry.value),
          ];
        }

        this.selectedAction = this.subticketActions[0];
        this.notifyVendorDateFormatted = '';
      } else if (this.mode == 'view') {
        await this.setDxiItemsForEdition();

        this.subticketActions = this.allSubticketStatuses.filter(
          (sts) => this.setSubticketNextStates(this.subticket).indexOf(sts.Code) > -1
        );

        this.selectedAction = null;
        this.notifyVendorDateFormatted = this.subticket.VendorNotifiedDate
          ? ' (' + moment(this.subticket.VendorNotifiedDate).format('DD-MM-YYYY HH:mm') + ')'
          : '';
      }
    });
  }

  public validateDueDate(): void {
    if (!this.subticket.DueDate) {
      this.dueDateError = { message: 'Plase select a Date & Time' };
      this.isDueDateValid = false;
      return;
    }
    this.isDueDateValid = true;
  }

  public checkBoxComparison(target: any) {
    return target.value != true || target.value != false;
  }

  public saveSubticket(): void {
    this.validateDueDate();
    if (!this.isDueDateValid) {
      notify(this.dueDateError['message'], 'error');
      return;
    }

    // Dynamic fields validations
    const dynamicFieldsValidator = this.dynamicFieldsValidator.instance.validate();
    if (!dynamicFieldsValidator.isValid) {
      return;
    }

    // if has value, should have file validation
    if (this.fieldCodeForFile) {
      if (this.invalidFile) {
        notify('Error: Trying to attach an invalid document', 'error');
        return;
      }

      if (this.fileToUpload) {
        this.subticketInfo[this.fieldCodeForFile] = this.fileToUpload.name;
      }
    }

    // Action selected for Status
    if (this.selectedAction) {
      this.subticket.SubticketStatus = !this.subticket.SubticketStatus
        ? new SubticketStatus()
        : this.subticket.SubticketStatus;
      this.subticket.SubticketStatus.Id = this.selectedAction.Id;
    }

    // Asigneed
    this.subticket.AsigneeId = this.selectedAgentId;
    // Vendor
    this.subticket.VendorId = this.selectedVendorId;

    this.subticket.DueDate = setCorrectDateTimeForBackend(this.subticket.DueDate);

    const subticketObject: CreateUpdateSubticket = {
      TicketId: this.ticketId && this.ticketId > 0 ? this.ticketId : null,
      Subticket: new CreateUpdateSubticketSubticket(this.subticket),
      SubticketInfo: this.subticketInfo,
    };

    if (this.mode == 'create') {
      this.httpSubticketService
        .insertSubticket(subticketObject)
        .pipe(takeUntil(this.destroyed))
        .subscribe({
          next: (subticketIdInserted) => {
            if (!this.fileToUpload) {
              this.saved.emit('OK');
              return;
            }

            const formData = new FormData();
            formData.append('file', this.fileToUpload, this.fileToUpload.name);

            this.httpSubticketService
              .attachDocumentToSubticket(subticketIdInserted, formData)
              .pipe(takeUntil(this.destroyed))
              .subscribe({
                next: () => this.saved.emit('OK'),
                error: (error) => {
                  console.log(error);
                  notify('Error trying to attach the file, please try again later', 'error', 10000);
                  this.saved.emit('OK');
                },
              });
          },
          error: (error) => {
            notify(error, 'error');
          },
        });
    } else {
      this.httpSubticketService
        .updateSubticket(this.subticket.Id, subticketObject)
        .pipe(takeUntil(this.destroyed))
        .subscribe({
          next: () => {
            // Upload new document
            if (this.fileToUpload) {
              const formData = new FormData();
              formData.append('file', this.fileToUpload, this.fileToUpload.name);

              this.httpSubticketService
                .attachDocumentToSubticket(this.subticket.Id, formData)
                .pipe(takeUntil(this.destroyed))
                .subscribe({
                  next: () => this.saved.emit('OK'),
                  error: (error) => {
                    console.log(error);
                    notify('Error trying to attach the file, please try again later', 'error', 10000);
                    this.saved.emit('OK');
                  },
                });
            }
            // Only select delete file and not upload a new one
            else if (this.shouldDeletePreviosFile && !this.fileToUpload) {
              this.httpSubticketService
                .deleteDocumentToSubticket(this.subticket.Id)
                .pipe(takeUntil(this.destroyed))
                .subscribe({
                  next: () => this.saved.emit('OK'),
                  error: (error) => {
                    console.log(error);
                    notify('Error trying deleting the file, please try again later', 'error', 10000);
                    this.saved.emit('OK');
                  },
                });
            } else {
              this.saved.emit('OK');
            }
          },
          error: (error) => {
            this.saved.emit(error);
          },
        });
    }
  }

  public deleteSubticket(): void {
    this.httpSubticketService
      .deleteSubticket(this.subticket.Id)
      .pipe(takeUntil(this.destroyed))
      .subscribe({
        next: () => this.deleted.emit('OK'),
        error: (error) => {
          console.log(error);
          notify('Error trying deleting the Subticket, please try again later', 'error', 10000);
        },
      });
  }

  async cancelSubticket() {
    this.canceled.emit('OK');
  }

  public async printConfirmation(): Promise<void> {
    this.validateDueDate();

    if (!this.isDueDateValid) {
      notify(this.dueDateError['message'], 'error');
      return;
    }

    this.pdfLoading = true;
    this.showPdfPreview = true;

    const generateItineraryRequest: GeneratePdfItineraryBySubticket[] = [];
    generateItineraryRequest.push({ SubticketId: this.subticket.Id, SubticketTypeId: this.subticket.SubticketType.Id });

    await lastValueFrom(
      this.httpSubticketService
        .generatePdfItineraryBySubtickets(generateItineraryRequest)
        .pipe(takeUntil(this.destroyed))
    )
      .then((byteArray) => {
        const pdfContents = byteArray;
        this.previewUrl = 'data:application/pdf;base64,' + pdfContents;
        this.pdfLoading = false;
      })
      .catch((error) => {
        notify(error, 'error');
        this.pdfLoading = false;
      });
  }

  public notifyVendor(): void {
    this.validateDueDate();

    if (!this.isDueDateValid) {
      notify(this.dueDateError['message'], 'error');
      return;
    }

    if (this.selectedVendorId && this.selectedVendorId != 0) {
      this.httpSubticketService.notifyVendor(this.subticket.Id, this.selectedVendorId).subscribe({
        next: (subticket) => {
          this.notifyVendorDateFormatted = ' (' + moment(subticket.VendorNotifiedDate).format('DD-MM-YYYY HH:mm') + ')';

          this.vendorNotified.emit('OK');
        },
        error: (e) => notify(e, 'error'),
      });
    } else {
      notify('Please, select a vendor', 'error');
    }
  }

  public showSubticketHistory(): void {
    this.subticketHistoryVisible = true;
    this.subticketHistory = this.subticketHistoryDatasource();
  }

  public onFileUploaderValueChanged(event: any): void {
    this.invalidFile = true;
    this.fileToUpload = null;

    const existElement = event && event.value && event.value[0];

    if (!existElement) {
      this.invalidFile = false;
      return;
    }

    if (event.component._files[0].isValid()) {
      this.fileToUpload = event.value[0];
      this.invalidFile = false;
    }
  }

  public downloadFile(fileName: string): void {
    this.httpSubticketService
      .downloadSubticketDocument(this.subticket.Id)
      .pipe(takeUntil(this.destroyed))
      .subscribe({
        next: (fileUrl) => {
          if (!fileUrl) {
            notify('Document not found, the document may have expired', 'warning', 10000);
            return;
          }

          const linkSource = fileUrl;
          const downloadLink = document.createElement('a');

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        },
        error: (error) => {
          console.log(error);
          notify('Error trying download the file, please try again later', 'error', 10000);
        },
      });
  }

  public deleteFile(): void {
    const currentFileItem = this.dxiItemsWithCustomTemplates.find((_) => _.Type == SubticketFieldTypeEnum.File);
    if (currentFileItem) {
      // Reset form and input uploader
      currentFileItem.Value = null;
      this.subticketInfo[this.fieldCodeForFile] = null;

      this.fileToUpload = null;
      this.shouldDeletePreviosFile = true;
    }
  }

  private setSubticketNextStates(subticket: Subticket): string[] {
    if (this.isHandoverNote) {
      switch (subticket.SubticketStatus.Code) {
        case HandoverStatusCodeEnum.Resolved.value:
          return [''];
        case HandoverStatusCodeEnum.New.value:
          return [
            HandoverStatusCodeEnum.InProgress.value,
            HandoverStatusCodeEnum.Acknowledged.value,
            HandoverStatusCodeEnum.Resolved.value,
          ];
        case HandoverStatusCodeEnum.InProgress.value:
          return [
            HandoverStatusCodeEnum.New.value,
            HandoverStatusCodeEnum.Acknowledged.value,
            HandoverStatusCodeEnum.Resolved.value,
          ];

        case HandoverStatusCodeEnum.Acknowledged.value:
          return [
            HandoverStatusCodeEnum.New.value,
            HandoverStatusCodeEnum.InProgress.value,
            HandoverStatusCodeEnum.Resolved.value,
          ];
        default:
          return [''];
      }
    } else {
      switch (subticket.SubticketStatus.Code) {
        case SubticketStatusCodeEnum.Enquiry.value:
          return [
            SubticketStatusCodeEnum.Open.value,
            SubticketStatusCodeEnum.Cancelled.value,
            SubticketStatusCodeEnum.Confirmed.value,
            SubticketStatusCodeEnum.Pending.value,
            SubticketStatusCodeEnum.OnHold.value,
            SubticketStatusCodeEnum.Waitlist.value,
          ];

        case SubticketStatusCodeEnum.Confirmed.value:
          return [SubticketStatusCodeEnum.Cancelled.value];

        case SubticketStatusCodeEnum.Open.value:
          return [
            SubticketStatusCodeEnum.Cancelled.value,
            SubticketStatusCodeEnum.Confirmed.value,
            SubticketStatusCodeEnum.Pending.value,
            SubticketStatusCodeEnum.OnHold.value,
            SubticketStatusCodeEnum.Waitlist.value,
          ];
        case SubticketStatusCodeEnum.Pending.value:
          return [
            SubticketStatusCodeEnum.OnHold.value,
            SubticketStatusCodeEnum.Waitlist.value,
            SubticketStatusCodeEnum.Cancelled.value,
            SubticketStatusCodeEnum.Confirmed.value,
          ];
        case SubticketStatusCodeEnum.OnHold.value:
          return [
            SubticketStatusCodeEnum.Waitlist.value,
            SubticketStatusCodeEnum.Cancelled.value,
            SubticketStatusCodeEnum.Confirmed.value,
          ];
        case SubticketStatusCodeEnum.Waitlist.value:
          return [
            SubticketStatusCodeEnum.OnHold.value,
            SubticketStatusCodeEnum.Cancelled.value,
            SubticketStatusCodeEnum.Confirmed.value,
          ];
        default:
          return [''];
      }
    }
  }

  private subticketHistoryDatasource(): DataSource {
    return new DataSource({
      store: new CustomStore({
        key: 'Id',
        load: () => {
          if (this.subticket.Id) {
            return lastValueFrom(
              this.httpSubticketService.getSubticketHistory(this.subticket.Id).pipe(takeUntil(this.destroyed))
            );
          } else return Promise.resolve([]);
        },
      }),
    });
  }

  private async setDxiItemsForCreation(): Promise<void> {
    const allItems = await lastValueFrom(
      this.httpCodesService.getSubticketTypeFields(this.subticket.SubticketType.Id).pipe(takeUntil(this.destroyed))
    ).then((fields) => {
      return fields.map((field) => {
        // default null value for all fields
        this.subticketInfo[field.Code] = null;

        // default value for checkbox
        if (field.Type == SubticketFieldTypeEnum.CheckBox) {
          this.subticketInfo[field.Code] = false;
        }

        const item = Object.assign(new DxiItem(), field);
        this.generateEditorOptions(item);

        // Logic for File
        if (field.Type == SubticketFieldTypeEnum.File) {
          this.fieldCodeForFile = field.Code;
        }

        return item;
      });
    });

    this.dxiItems = allItems.filter((_) => !_.UseCustomTemplate);
    this.dxiItemsWithCustomTemplates = allItems.filter((_) => _.UseCustomTemplate);
  }

  private async setDxiItemsForEdition(): Promise<void> {
    // Subticket View/Edition
    const subticketInfo: SubticketTypeFieldValue[] = await lastValueFrom(
      this.httpSubticketService.getSubticketInfo(this.subticket.Id).pipe(takeUntil(this.destroyed))
    );

    const allItems = subticketInfo.map((field) => {
      // Default value
      this.shouldDeletePreviosFile = false;

      switch (field.Type) {
        case SubticketFieldTypeEnum.CheckBox:
        case SubticketFieldTypeEnum.Currency:
          this.subticketInfo[field.Code] = JSON.parse(field.Value.toLowerCase());
          break;
        default:
          this.subticketInfo[field.Code] = field.Value;
          break;
      }

      const item = Object.assign(new DxiItem(), field);

      this.generateEditorOptions(item);

      // Logic for File
      if (field.Type == SubticketFieldTypeEnum.File) {
        this.fieldCodeForFile = field.Code;
      }

      return item;
    });

    this.dxiItems = allItems.filter((_) => !_.UseCustomTemplate);
    this.dxiItemsWithCustomTemplates = allItems.filter((_) => _.UseCustomTemplate);
  }

  private generateEditorOptions(item: DxiItem): void {
    const itemConfig = SubticketFieldTypeConfig.get(item.Type);

    item.EditorOptions = itemConfig.EditorOptions;
    item.EditorType = itemConfig.EditorType;
    item.UseCustomTemplate = itemConfig.UseCustomTemplate ? itemConfig.UseCustomTemplate : false;
  }

  private async loadInitialData(): Promise<void> {
    this.agents = await lastValueFrom(this.httpUserService.getAgents().pipe(takeUntil(this.destroyed)));

    this.allSubticketStatuses = await lastValueFrom(
      this.httpCodesService.getSubticketStatuses().pipe(takeUntil(this.destroyed))
    );
  }
}
