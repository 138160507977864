import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  // TODO: Use some encrypt Service for set and get

  public setData(storageKey: string, data: any): void {
    localStorage.setItem(storageKey, JSON.stringify(data));
  }

  public getData<T>(storageKey: string): T {
    const data = localStorage.getItem(storageKey);

    if (!data) {
      return null;
    }

    return JSON.parse(data) as T;
  }

  public removeData(storageKey: string): void {
    localStorage.removeItem(storageKey);
  }

  public removeAllData(): void {
    localStorage.clear();
  }
}
