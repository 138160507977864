import { Component, OnInit } from '@angular/core';

import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';

import { HttpCodeService } from 'src/app/services/http/http-code.service';
import { BaseSubscriptionComponent } from 'src/app/base/base.suscription.component';
import { lastValueFrom, takeUntil } from 'rxjs';
import { setUpdateValues } from 'src/app/helper/common-helper';
import { CreateUpdateTherapist } from 'src/app/models/requests/create-update-therapist';
import { TreatmentType } from 'src/app/models/view-models/treatment-type';
import { Therapist } from 'src/app/models/view-models/therapist';

@Component({
  selector: 'app-therapists',
  templateUrl: './therapists.component.html',
})
export class TherapistsComponent extends BaseSubscriptionComponent implements OnInit {
  public therapists: DataSource;
  public allTreatmentTypes: TreatmentType[] = [];

  constructor(private httpCodesService: HttpCodeService) {
    super();
  }

  public async ngOnInit(): Promise<void> {
    this.allTreatmentTypes = await lastValueFrom(
      this.httpCodesService.getTreatmentTypes().pipe(takeUntil(this.destroyed))
    );

    this.therapists = this.therapistsDatasource();
  }

  public calculateTreatmentDisplayValue(container: any, options: any): void {
    const containerText = container[0];

    if (!containerText) {
      return;
    }

    const noBreakSpace = '\u00A0';

    const treatments = (options.value || []).map((treatment: TreatmentType) =>
      options.column!.lookup!.calculateCellValue!(treatment.Id)
    );
    const text = treatments.join(', ');

    containerText.textContent = text || noBreakSpace;
    containerText.title = text;
  }

  public async setPreSelectedValues(event: any, cellInfo: any): Promise<void> {
    let data = await event.component.getDataSource().load();

    if (cellInfo && cellInfo.value) {
      const preSelectedIds = (cellInfo.value as TreatmentType[]).map((_) => _.Id);

      let selectedArray = [];

      selectedArray = (data as TreatmentType[]).filter((_) => preSelectedIds.includes(_.Id));

      event.component.option('value', selectedArray);
    }
  }

  private therapistsDatasource(): DataSource {
    return new DataSource({
      store: new CustomStore({
        key: 'Id',
        load: () => lastValueFrom(this.httpCodesService.getAllTherapists().pipe(takeUntil(this.destroyed))),
        update: (id, values) => {
          const toUpdate = this.therapists.items().find((_) => _.Id == id) as Therapist;

          setUpdateValues(values, toUpdate);

          const dataToSend = new CreateUpdateTherapist();
          dataToSend.Id = toUpdate.Id;
          dataToSend.FullName = toUpdate.FullName;
          dataToSend.TreatmentTypeIds = toUpdate.TreatmentTypes.map((_) => _.Id);

          return lastValueFrom(this.httpCodesService.updateTherapist(dataToSend).pipe(takeUntil(this.destroyed)));
        },
        remove: (id) => lastValueFrom(this.httpCodesService.deleteTherapist(id).pipe(takeUntil(this.destroyed))),
        insert: (therapist) => {
          const dataToSend = new CreateUpdateTherapist();
          dataToSend.FullName = therapist.FullName;
          dataToSend.TreatmentTypeIds = therapist.TreatmentTypes.map((_) => _.Id);

          return lastValueFrom(this.httpCodesService.insertTherapist(dataToSend).pipe(takeUntil(this.destroyed)));
        },
      }),
    });
  }
}
