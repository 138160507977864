import { Component, OnInit } from '@angular/core';

import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';

import { lastValueFrom, takeUntil } from 'rxjs';
import { HttpCodeService } from 'src/app/services/http/http-code.service';
import { BaseSubscriptionComponent } from 'src/app/base/base.suscription.component';
import { setUpdateValues } from 'src/app/helper/common-helper';

@Component({
  selector: 'app-ticket-statuses',
  templateUrl: './ticket-statuses.component.html',
})
export class TicketStatusesComponent extends BaseSubscriptionComponent implements OnInit {
  public statuses: DataSource;

  constructor(private httpCodesService: HttpCodeService) {
    super();
  }

  ngOnInit() {
    this.statuses = this.statusesDatasource();
  }

  setEditedValue(e, cellInfo) {
    cellInfo.setValue(e.value);
  }

  public statusesDatasource() {
    return new DataSource({
      store: new CustomStore({
        key: 'Id',
        load: () => lastValueFrom(this.httpCodesService.getTicketStatuses().pipe(takeUntil(this.destroyed))),
        update: (id, values) => {
          const toUpdate = this.statuses.items().find((_) => _.Id == id);

          setUpdateValues(values, toUpdate);

          return lastValueFrom(this.httpCodesService.updateTicketStatus(toUpdate).pipe(takeUntil(this.destroyed)));
        },
        remove: (id) => lastValueFrom(this.httpCodesService.deletePriority(id).pipe(takeUntil(this.destroyed))),
        insert: (ticketStatus) =>
          lastValueFrom(this.httpCodesService.insertTicketStatus(ticketStatus).pipe(takeUntil(this.destroyed))),
      }),
    });
  }
}
