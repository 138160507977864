import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class AuthenticationService {
  authenticatedChanged = new BehaviorSubject(false);

  constructor(private http: HttpClient, @Inject('API_URL') private serverUrl: string) {
    this.authenticatedChanged.next(false);
  }

  async authenticate(username: string, password: string) {
    // Check if the current token is ok
    if (localStorage.getItem('access_token')) {
      this.authenticatedChanged.next(true);
      return 'OK';
    }

    const body = `grant_type=password&username=${username}&password=${password}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    try {
      const response = await firstValueFrom(this.http.post<any>(this.serverUrl + 'token', body, { headers: headers }));
      if (response.access_token) {
        localStorage.setItem('access_token', response.access_token);
        localStorage.setItem('currentUser', response.userName);
        this.authenticatedChanged.next(true);
        return 'OK';
      } else {
        return 'error';
      }
    } catch (e) {
      return e;
    }
  }

  getToken() {
    const token = localStorage.getItem('access_token');
    if (token) return token;
    else {
      this.authenticatedChanged.next(false);
      return null;
    }
  }

  getUser() {
    return localStorage.getItem('currentUser');
  }

  getRequest(url) {
    const token = this.getToken();

    if (token) {
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);

      const headerObj = {
        headers: headers,
      };

      return this.http.get<any>(url, headerObj);
    } else return null;
  }

  logout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('currentUser');
    this.authenticatedChanged.next(false);
  }
}
