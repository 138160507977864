import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HandoverNote } from 'src/app/models/view-models/handover-note';
@Injectable({
  providedIn: 'root',
})
export class HttpHandoverService {
  private baseUrl = `${this.serverUrl}Handovers`;

  constructor(private httpClient: HttpClient, @Inject('API_URL') public serverUrl: string) {}

  public getMyHandoverNotes(hotelId: number): Observable<HandoverNote[]> {
    return this.httpClient.get<HandoverNote[]>(`${this.baseUrl}/GetMyHandoverNotes/${hotelId}`);
  }
}
