import { Component, OnInit } from '@angular/core';

import { BaseSubscriptionComponent } from '../base/base.suscription.component';
import { HttpReservationService } from '../services/http/http-reservation.service';
import { HttpCodeService } from '../services/http/http-code.service';
import { BehaviorSubject, lastValueFrom, takeUntil } from 'rxjs';
import * as moment from 'moment';
import { SchedulerRow } from '../models/view-models/scheduler-row';
import { SchedulerColumn } from '../models/view-models/scheduler-column';
import { ReservationScheduler } from '../models/view-models/reservation-scheduler';
import { AppointmentFormOpeningEvent, ContentReadyEvent } from 'devextreme/ui/scheduler';
import { ModeComponentEnum } from '../models/enums/mode-component';
import { TreatmentRoom } from '../models/view-models/treatment-room';
import { SubticketType } from '../models/view-models/subticket-type';
import { SubticketTypeEnum } from '../models/enums/subticket-type.enum';

@Component({
  templateUrl: './reservations.component.html',
  styleUrls: ['./reservations.component.scss'],
})
export class ReservationsComponent extends BaseSubscriptionComponent implements OnInit {
  public schedulerRowData: SchedulerRow[] = [];
  public schedulerColumnData: SchedulerColumn[] = [];
  public currentDate = moment().toDate();
  public reservationDetailVisible: boolean = false;
  public modeChanged = new BehaviorSubject<[ModeComponentEnum, ReservationScheduler]>([null, null]);
  public allTreatmentRooms: TreatmentRoom[] = [];

  public subticketTypes: SubticketType[];

  constructor(private httpReservationService: HttpReservationService, private httpCodesService: HttpCodeService) {
    super();
  }

  public ngOnInit(): void {
    this.searchSubticketTypes();
    this.searchTreatmentRooms();
    this.searchReservations(this.currentDate);
  }

  // #region Scheduler Events

  public onSchedulerOptionChange(event: any): void {
    if (event && event.name === 'currentDate') {
      this.searchReservations(event.value);
    }
  }

  public onSchedulerReady(event: ContentReadyEvent): void {
    // Set Time Line in current Hour
    event.component.scrollTo(new Date());
  }

  public onSchedulReservationFormOpening(event: AppointmentFormOpeningEvent): void {
    // Avoid open default pop up
    event.cancel = true;

    if (event.appointmentData && event.appointmentData instanceof ReservationScheduler) {
      this.modeChanged.next([ModeComponentEnum.Update, event.appointmentData]);
    } else {
      const tmpReservation = new ReservationScheduler();
      tmpReservation.treatmentRoomId = event.appointmentData['treatmentRoomId'];
      tmpReservation.startDate = event.appointmentData.startDate as Date;
      tmpReservation.endDate = event.appointmentData.endDate as Date;

      this.modeChanged.next([ModeComponentEnum.Create, tmpReservation]);
    }

    this.reservationDetailVisible = true;
  }

  // #endregion Scheduler Events

  // #region Date Picker Panel

  public dateChanged(event: any): void {
    this.searchReservations(event.value);
  }

  public newGenericReservation(): void {
    const tmpReservation = new ReservationScheduler();
    tmpReservation.treatmentRoomId = null;
    tmpReservation.startDate = new Date();
    tmpReservation.endDate = new Date();

    this.modeChanged.next([ModeComponentEnum.Create, tmpReservation]);

    this.reservationDetailVisible = true;
  }

  private searchSubticketTypes(): void {
    lastValueFrom(this.httpCodesService.getSubticketTypes().pipe(takeUntil(this.destroyed))).then((subticketTypes) => {
      this.subticketTypes = subticketTypes;
    });
  }

  // #endregion Date Picker Panel

  public reservationDetailClosed(): void {
    this.reservationDetailVisible = false;
  }

  public reservationSaved(): void {
    this.reservationDetailVisible = false;
    this.searchReservations(this.currentDate);
  }

  private searchReservations(date: Date): void {
    // update current date
    this.currentDate = date;

    lastValueFrom(
      this.httpReservationService
        .getReservationsByDate(moment(date).format('YYYY-MM-DD'))
        .pipe(takeUntil(this.destroyed))
    ).then((reservations) => {
      this.schedulerRowData = reservations.map((reservation) => {
        const schedulerRow = new ReservationScheduler();
        schedulerRow.treatmentRoomId = reservation.TreatmentRoom.Id; // For Create columns by Rooms
        schedulerRow.reservation = reservation;

        schedulerRow.startDate = moment(reservation.Subticket.DueDate).toDate();
        schedulerRow.endDate = moment(reservation.Subticket.DueDate).add(reservation.Duration).toDate();

        schedulerRow.text = reservation.TreatmentType.Name;

        return schedulerRow;
      });
    });
  }

  private searchTreatmentRooms(): void {
    lastValueFrom(this.httpCodesService.getTreatmentRooms().pipe(takeUntil(this.destroyed))).then((rooms) => {
      this.allTreatmentRooms = rooms;

      this.schedulerColumnData = rooms.map((room) => {
        const schedulerColumn = new SchedulerColumn();

        schedulerColumn.id = room.Id;
        schedulerColumn.text = room.Name;
        schedulerColumn.color = room.Color;

        return schedulerColumn;
      });
    });
  }
}
