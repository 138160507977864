import { Component } from '@angular/core';
import { AdminMenu, SuperAdminMenu } from '../models/enums/admin-menu-enum';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
})
export class AdminComponent {
  public AdminMenu = AdminMenu;
  public SuperAdminMenu = SuperAdminMenu;

  public adminMenuItems: AdminMenu[] = Object.values(AdminMenu);

  public superAdminMenuItems: SuperAdminMenu[] = Object.values(SuperAdminMenu);

  public itemSelected = null;

  public optionSelected(e): void {
    this.itemSelected = e.itemData;
  }
}
