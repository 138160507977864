import { Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, lastValueFrom, takeUntil } from 'rxjs';

import { DxDataGridComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import notify from 'devextreme/ui/notify';

import { ConfigService } from '../services/config.service';
import { HttpHandoverService } from '../services/http/http-handover.service';
import { BaseSubscriptionComponent } from '../base/base.suscription.component';
import { Hotel } from '../models/view-models/hotel';
import { HandoverNote } from '../models/view-models/handover-note';
import { HttpSubticketService } from '../services/http/http-subticket.service';
import { HandoverStatusCodeFilterEnum } from '../models/enums/handover-note-status.enum';
import { GeneratePdfItineraryBySubticket } from '../models/requests/generate-pdf-itinerary-by-subticket';

@Component({
  templateUrl: './my-handover-notes.component.html',
})
export class MyHandoverNotesComponent extends BaseSubscriptionComponent implements OnInit {
  public subtickets: DataSource;
  public subticketHistory: DataSource;
  public subticketHistoryVisible = false;
  public subticketDetailVisible = false;
  public subticketChanged = new BehaviorSubject(null);
  public modeChanged = new BehaviorSubject('view');
  public pdfLoading = false;
  public showPdfPreview = false;
  public previewUrl = '';
  public defaultStatusToShow = [
    HandoverStatusCodeFilterEnum.New.value,
    HandoverStatusCodeFilterEnum.InProgress.value,
    HandoverStatusCodeFilterEnum.Acknowledged.value,
  ];

  @ViewChild(DxDataGridComponent, { static: false }) private subticketsGrid: DxDataGridComponent;
  private hotel: Hotel;
  private selectedSubticketId: number;

  constructor(
    private config: ConfigService,
    private httpHandoverService: HttpHandoverService,
    private httpSubticketService: HttpSubticketService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.hotel = this.config.getCurrentHotel();
    this.subtickets = this.subticketsDatasource();
  }

  public async printItinerary(): Promise<void> {
    const subtickets = this.subticketsGrid.instance.getSelectedRowsData();

    if (!subtickets.length) {
      notify('Please, select some requests');
      return;
    }

    this.showPdfPreview = true;
    this.pdfLoading = true;

    const generateItineraryRequest: GeneratePdfItineraryBySubticket[] = [];

    subtickets.forEach((element) => {
      generateItineraryRequest.push({
        SubticketId: element.Id,
        SubticketTypeId: element.SubticketType.Id,
      });
    });

    this.httpSubticketService
      .generatePdfItineraryBySubtickets(generateItineraryRequest)
      .pipe(takeUntil(this.destroyed))
      .subscribe({
        next: (byteArray) => {
          const pdfContents = byteArray;
          this.previewUrl = 'data:application/pdf;base64,' + pdfContents;
          this.pdfLoading = false;
        },
        error: (error) => {
          notify(error, 'error');
          this.pdfLoading = false;
          this.previewUrl = '';
        },
        complete: () => console.info('complete'),
      });
  }

  public showSubticketHistory(handoverNote: HandoverNote): void {
    this.selectedSubticketId = handoverNote.Id;
    this.subticketHistoryVisible = true;
    this.subticketHistory = this.subticketHistoryDatasource();
  }

  public showSubticketDetails(handoverNote: HandoverNote): void {
    this.modeChanged.next('view');
    this.subticketChanged.next(handoverNote);
    this.subticketDetailVisible = true;
  }

  public hideSubticketDetails(): void {
    this.subticketDetailVisible = false;
  }

  public subticketSaved(): void {
    this.subticketDetailVisible = false;
    this.subticketsGrid.instance.refresh();
  }

  public closeItineraryPreview(): void {
    this.showPdfPreview = false;
    this.pdfLoading = false;
    this.previewUrl = '';
  }

  public subticketComponentClose(): void {
    this.subticketDetailVisible = false;
  }

  private subticketsDatasource(): DataSource {
    return new DataSource({
      store: new CustomStore({
        key: 'Id',
        load: () =>
          lastValueFrom(this.httpHandoverService.getMyHandoverNotes(this.hotel.Id).pipe(takeUntil(this.destroyed))),
      }),
    });
  }

  private subticketHistoryDatasource(): DataSource {
    return new DataSource({
      store: new CustomStore({
        key: 'Id',
        load: () =>
          lastValueFrom(
            this.httpSubticketService.getSubticketHistory(this.selectedSubticketId).pipe(takeUntil(this.destroyed))
          ),
      }),
    });
  }
}
