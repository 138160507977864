import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { RemoteService } from './remote.service';
import { AuthenticationService } from './authentication.service';
import { StorageService } from './storage.service';
import { Hotel } from '../models/view-models/hotel';
import { StoragePersistKeyEnum } from '../models/enums/storage-persist-key.enum';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  currentHotel: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    private remote: RemoteService,
    private auth: AuthenticationService,
    private storageService: StorageService
  ) {
    this.initCurrentHotel();
  }

  async initCurrentHotel() {
    const user = this.auth.getUser();
    if (user != null) {
      const hotel = await this.remote.getRequest('Hotels/GetHotelLinkedToUser');
      this.setCurrentHotel(hotel);
    }
  }

  public setCurrentHotel(hotel: Hotel) {
    this.storageService.setData(StoragePersistKeyEnum.CurrentUserHotel, hotel);

    this.currentHotel.next(hotel);
  }

  public getCurrentHotel(): Hotel {
    return this.storageService.getData<Hotel>(StoragePersistKeyEnum.CurrentUserHotel);
  }
}
