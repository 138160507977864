export const HtmlEditor_Toolbar_Items = [
  'bold',
  'italic',
  'underline',
  'subscript',
  'superscript',
  'separator',
  'alignLeft',
  'alignRight',
  'alignCenter',
  'alignJustify',
  'separator',
  'bulletList',
  'orderedList',
  'increaseIndent',
  'decreaseIndent',
  'separator',
  'color',
  'background',
  'separator',
  'link',
  'image',
  'html',
  'separator',
  'clean',
  'undo',
  'redo',
];
