import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { CreateUpdateSubticket } from 'src/app/models/requests/create-update-subticket';
import { GeneratePdfItineraryBySubticket } from 'src/app/models/requests/generate-pdf-itinerary-by-subticket';
import { GeneratePdfItineraryByText } from 'src/app/models/requests/generate-pdf-itinerary-by-text';
import { GenerateReplyOrItineraryText } from 'src/app/models/requests/generate-reply-or-itinerary-text';
import { SearchSubticketTemplates } from 'src/app/models/requests/search-subticket-templates';

import { Subticket } from 'src/app/models/view-models/subticket';
import { SubticketTemplate } from 'src/app/models/view-models/subticket-template';
import { SubticketTypeFieldValue } from 'src/app/models/view-models/subticket-type-field-value';
@Injectable({
  providedIn: 'root',
})
export class HttpSubticketService {
  private baseUrl = `${this.serverUrl}Subtickets`;

  constructor(private httpClient: HttpClient, @Inject('API_URL') public serverUrl: string) {}

  public getSubticketHistory(subticketId: number): Observable<Subticket[]> {
    return this.httpClient.get<Subticket[]>(`${this.baseUrl}/GetSubticketHistory/${subticketId}`);
  }

  public getSubticketsFilteredByDate(date: string): Observable<Subticket[]> {
    return this.httpClient.get<Subticket[]>(`${this.baseUrl}/GetSubticketsFilteredByDate/${date}`);
  }

  public getSubticketsFilteredByText(textFilter: string): Observable<Subticket[]> {
    return this.httpClient.post<Subticket[]>(`${this.baseUrl}/GetSubticketsFilteredByText/`, {
      filterText: textFilter,
    });
  }

  public getSubticketsByTicket(ticketId: number): Observable<any> {
    return this.httpClient.get<any>(`${this.baseUrl}/GetSubticketsByTicket/${ticketId}`);
  }

  public getSubticketTypeVariables(subticketTypeId: number): Observable<any> {
    return this.httpClient.get<any>(`${this.baseUrl}/GetSubticketTypeVariables/${subticketTypeId}`);
  }

  public generatePdfItineraryByText(textItinerary: GeneratePdfItineraryByText): Observable<any> {
    return this.httpClient.post<any>(`${this.baseUrl}/GeneratePdfItineraryByText/`, textItinerary);
  }

  public generatePdfItineraryBySubtickets(subtickets: GeneratePdfItineraryBySubticket[]): Observable<any> {
    return this.httpClient.post<any>(`${this.baseUrl}/GeneratePdfItineraryBySubtickets/`, subtickets);
  }

  public getMySubtickets(): Observable<Subticket[]> {
    return this.httpClient.get<Subticket[]>(`${this.baseUrl}/GetMySubtickets/`);
  }

  public getSubticketTemplates(ticketId: number): Observable<SubticketTemplate[]> {
    return this.httpClient.get<SubticketTemplate[]>(`${this.baseUrl}/GetSubticketTemplates/${ticketId}`);
  }

  public searchSubticketTemplatesBySubticketIds(
    searchFilter: SearchSubticketTemplates
  ): Observable<SubticketTemplate[]> {
    return this.httpClient.post<SubticketTemplate[]>(`${this.baseUrl}/SearchSubticketTemplates/`, searchFilter);
  }

  public generateReplyOrItineraryText(subticketsTemplates: GenerateReplyOrItineraryText[]): Observable<string> {
    return this.httpClient.post<string>(`${this.baseUrl}/GenerateReplyOrItineraryText/`, subticketsTemplates);
  }

  public insertSubticket(request: CreateUpdateSubticket): Observable<number> {
    return this.httpClient.post<number>(`${this.baseUrl}/Insert/`, request);
  }

  public updateSubticket(subticketId: number, request: CreateUpdateSubticket): Observable<void> {
    return this.httpClient.put<void>(`${this.baseUrl}/Update/${subticketId}`, request);
  }

  public attachDocumentToSubticket(subticketId: number, request: FormData): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUrl}/AttachDocumentToSubticket/${subticketId}`, request);
  }

  public deleteDocumentToSubticket(subticketId: number): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUrl}/DeleteDocumentToSubticket/${subticketId}`, null);
  }

  public deleteSubticket(subticketId: number): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUrl}/Delete/${subticketId}`, null);
  }

  public notifyVendor(subticketId: number, vendorId: number): Observable<Subticket> {
    return this.httpClient.post<Subticket>(`${this.baseUrl}/NotifyVendor/${subticketId}/${vendorId}`, null);
  }

  public getSubticketInfo(subticketId: number): Observable<SubticketTypeFieldValue[]> {
    return this.httpClient.get<SubticketTypeFieldValue[]>(`${this.baseUrl}/GetSubticketInfo/${subticketId}`);
  }

  public downloadSubticketDocument(subticketId: number): Observable<string> {
    return this.httpClient.post<string>(`${this.baseUrl}/DownloadSubticketDocument/${subticketId}`, null);
  }
}
