import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { CreateUpdateTherapist } from 'src/app/models/requests/create-update-therapist';
import { Priority } from 'src/app/models/view-models/priority';
import { SubticketStatus } from 'src/app/models/view-models/subticket-status';
import { SubticketType } from 'src/app/models/view-models/subticket-type';
import { SubticketTypeField } from 'src/app/models/view-models/subticket-type-field';
import { Therapist } from 'src/app/models/view-models/therapist';
import { TicketStatus } from 'src/app/models/view-models/ticket-status';
import { TreatmentRoom } from 'src/app/models/view-models/treatment-room';
import { TreatmentType } from 'src/app/models/view-models/treatment-type';

@Injectable({
  providedIn: 'root',
})
export class HttpCodeService {
  private baseUrl = `${this.serverUrl}Codes`;

  // TODO: Create all missing objects, view models
  // FIXME: Change all bad POST declarations to respective DELETE and PUT (necessary changes in front-end and back-end)

  constructor(private httpClient: HttpClient, @Inject('API_URL') public serverUrl: string) {}

  public getTimezones(): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.baseUrl}/GetTimezones`);
  }

  public getSources(): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.baseUrl}/GetSources`);
  }

  public getFileContentsByUUID(uuid: string): Observable<any> {
    return this.httpClient.get<any>(`${this.baseUrl}/GetFileContentsByUUID/${uuid}`);
  }

  // #region Subticket Type
  public getSubticketTypes(): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.baseUrl}/GetSubticketTypes`);
  }

  public updateSubticketType(subticketTypeId: number, subticketType: any): Observable<string> {
    return this.httpClient.post<string>(`${this.baseUrl}/UpdateSubticketType/${subticketTypeId}`, subticketType);
  }

  public deleteSubticketType(subticketTypeId: number): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUrl}/DeleteSubticketType/${subticketTypeId}`, null);
  }

  public insertSubticketType(subticketType: any): Observable<string> {
    return this.httpClient.post<string>(`${this.baseUrl}/InsertSubticketType`, subticketType);
  }
  // #endregion Subticket Type

  // #region Subticket Statuses
  public getSubticketStatuses(): Observable<SubticketStatus[]> {
    return this.httpClient.get<SubticketStatus[]>(`${this.baseUrl}/SubticketStatus/GetSubticketStatuses`);
  }

  public updateSubticketStatus(subticketStatus: SubticketStatus): Observable<string> {
    return this.httpClient.post<string>(`${this.baseUrl}/SubticketStatus/UpdateSubticketStatus`, subticketStatus);
  }

  public deleteSubticketStatus(subticketStatusId: number): Observable<void> {
    return this.httpClient.post<void>(
      `${this.baseUrl}/SubticketStatus/DeleteSubticketStatus/${subticketStatusId}`,
      null
    );
  }

  public insertSubticketStatus(subticketStatus: SubticketStatus): Observable<any> {
    return this.httpClient.post<any>(`${this.baseUrl}/SubticketStatus/InsertSubticketStatus`, subticketStatus);
  }
  // #endregion Subticket Statuses

  // #region Templates
  public getTemplates(): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.baseUrl}/GetTemplates`);
  }

  public updateTemplate(templateId: number, template: any): Observable<string> {
    return this.httpClient.post<string>(`${this.baseUrl}/UpdateTemplate/${templateId}`, template);
  }

  public deleteTemplate(templateId: number): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUrl}/DeleteTemplate/${templateId}`, null);
  }

  public insertTemplate(template: any): Observable<string> {
    return this.httpClient.post<string>(`${this.baseUrl}/InsertTemplate`, template);
  }
  // #endregion Templates

  // #region Vendors
  public getVendors(subticketTypeId: number): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.baseUrl}/GetVendors/${subticketTypeId}`);
  }
  // #endregion Vendors

  // #region Subticket Type Fields
  public getSubticketTypeFields(subticketTypeId: number): Observable<SubticketTypeField[]> {
    return this.httpClient.get<SubticketTypeField[]>(
      `${this.baseUrl}/SubticketTypeField/GetSubticketTypeFields/${subticketTypeId}`
    );
  }

  public updateSubticketTypeField(subticketTypeField: SubticketTypeField): Observable<void> {
    return this.httpClient.post<void>(
      `${this.baseUrl}/SubticketTypeField/UpdateSubticketTypeField`,
      subticketTypeField
    );
  }

  public deleteSubticketTypeField(subticketTypeFieldId: number): Observable<void> {
    return this.httpClient.post<void>(
      `${this.baseUrl}/SubticketTypeField/DeleteSubticketTypeField/${subticketTypeFieldId}`,
      null
    );
  }

  public insertSubticketTypeField(subticketTypeField: SubticketTypeField): Observable<any> {
    return this.httpClient.post<any>(`${this.baseUrl}/SubticketTypeField/InsertSubticketTypeField`, subticketTypeField);
  }
  // #endregion Subticket Type Fields

  // #region Priorities
  public getPriorities(): Observable<Priority[]> {
    return this.httpClient.get<Priority[]>(`${this.baseUrl}/Priority/GetPriorities`);
  }

  public updatePriority(priority: Priority): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUrl}/Priority/UpdatePriority`, priority);
  }

  public deletePriority(priorityId: number): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUrl}/Priority/DeletePriority/${priorityId}`, null);
  }

  public insertPriority(priority: Priority): Observable<any> {
    return this.httpClient.post<any>(`${this.baseUrl}/Priority/InsertPriority`, priority);
  }
  // #endregion Priorities

  // #region Ticket Status
  public getTicketStatuses(): Observable<TicketStatus[]> {
    return this.httpClient.get<TicketStatus[]>(`${this.baseUrl}/Ticket/GetTicketStatuses`);
  }

  public updateTicketStatus(ticketStatus: TicketStatus): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUrl}/Ticket/UpdateTicketStatus`, ticketStatus);
  }

  public deleteTicketStatus(ticketStatusId: number): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUrl}/Ticket/DeleteTicketStatus/${ticketStatusId}`, null);
  }

  public insertTicketStatus(ticketStatus: TicketStatus): Observable<any> {
    return this.httpClient.post<any>(`${this.baseUrl}/Ticket/InsertTicketStatus`, ticketStatus);
  }
  // #endregion Ticket Status

  // #region Treatment Types
  public getTreatmentTypes(): Observable<TreatmentType[]> {
    return this.httpClient.get<TreatmentType[]>(`${this.baseUrl}/TreatmentType/GetTreatmentTypes`);
  }

  public updateTreatmentType(treatmentType: TreatmentType): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUrl}/TreatmentType/UpdateTreatmentType`, treatmentType);
  }

  public deleteTreatmentType(treatmentTypeId: number): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUrl}/TreatmentType/DeleteTreatmentType/${treatmentTypeId}`, null);
  }

  public insertTreatmentType(treatmentType: TreatmentType): Observable<any> {
    return this.httpClient.post<any>(`${this.baseUrl}/TreatmentType/InsertTreatmentType`, treatmentType);
  }
  // #endregion Treatment Types

  // #region Treatment Rooms
  public getTreatmentRooms(): Observable<TreatmentRoom[]> {
    return this.httpClient.get<TreatmentRoom[]>(`${this.baseUrl}/TreatmentRoom/GetTreatmentRooms`);
  }

  public updateTreatmentRoom(treatmentRoom: TreatmentRoom): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUrl}/TreatmentRoom/UpdateTreatmentRoom`, treatmentRoom);
  }

  public deleteTreatmentRoom(treatmentRoomId: number): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUrl}/TreatmentRoom/DeleteTreatmentRoom/${treatmentRoomId}`, null);
  }

  public insertTreatmentRoom(treatmentRoom: TreatmentRoom): Observable<any> {
    return this.httpClient.post<any>(`${this.baseUrl}/TreatmentRoom/InsertTreatmentRoom`, treatmentRoom);
  }
  // #endregion Treatment Rooms

  // #region Therapists
  public getAllTherapists(): Observable<Therapist[]> {
    return this.httpClient.get<Therapist[]>(`${this.baseUrl}/Therapist/GetAllTherapists`);
  }

  public updateTherapist(therapist: CreateUpdateTherapist): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUrl}/Therapist/UpdateTherapist`, therapist);
  }

  public deleteTherapist(therapistId: number): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUrl}/Therapist/DeleteTherapist/${therapistId}`, null);
  }

  public insertTherapist(therapist: CreateUpdateTherapist): Observable<any> {
    return this.httpClient.post<any>(`${this.baseUrl}/Therapist/InsertTherapist`, therapist);
  }
  // #endregion Therapists
}
