import { Component, OnInit } from '@angular/core';

import * as moment from 'moment';

import { RemoteService } from '../services/remote.service'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    inboxIncoming: any
    constructor(private remote: RemoteService ) { }

    async ngOnInit() {
        let dateIni = moment().subtract('month', 1).format('YYYY-MM-DD')
        let dateEnd = moment().format('YYYY-MM-DD')
        let url = 'Dashboard/GetInboxHour/CREATE/' + dateIni + '/' + dateEnd
        this.inboxIncoming = await this.remote.getRequest(url)
    }
}
