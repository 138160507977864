import * as moment from 'moment';
import { Duration } from 'moment';

export class ReservationDuration {
  public Name: string;
  public Value: Duration;
}

export function getTimeString(duration: Duration) {
  return moment.utc(duration.asMilliseconds()).format('HH:mm:ss');
}
