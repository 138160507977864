import { Nullable } from '../view-models/nullable-type';

export class CreateUpdateReservation {
  public Id: number;
  public Subticket: CreateUpdateReservationSubticket;
  public Duration: string;
  public TreatmentTypeId: number;
  public TreatmentRoomId: number;
  public TherapistId: number;
}

export class CreateUpdateReservationSubticket {
  public Id: number;
  public DueDate: Date;
  public Notes: Nullable<string>;
  public GuestName: Nullable<string>;
  public NumberOfGuests: Nullable<number>;
  public AsigneeId: Nullable<string>;
}
