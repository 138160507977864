import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  public isLoading = new BehaviorSubject<boolean>(false);

  public async show(): Promise<void> {
    this.isLoading.next(true);
  }

  public async hide(): Promise<void> {
    this.isLoading.next(false);
  }
}
