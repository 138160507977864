import { Component, OnInit } from '@angular/core';

import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';

import { HttpCodeService } from 'src/app/services/http/http-code.service';
import { BaseSubscriptionComponent } from 'src/app/base/base.suscription.component';
import { lastValueFrom, takeUntil } from 'rxjs';
import { setCorrectDateTimeForBackend, setUpdateValues } from 'src/app/helper/common-helper';
import { TreatmentRoom } from 'src/app/models/view-models/treatment-room';

@Component({
  selector: 'app-treatment-rooms',
  templateUrl: './treatment-rooms.component.html',
})
export class TreatmentRoomsComponent extends BaseSubscriptionComponent implements OnInit {
  public treatmentRooms: DataSource;

  constructor(private httpCodesService: HttpCodeService) {
    super();
  }

  public ngOnInit(): void {
    this.treatmentRooms = this.treatmentRoomsDatasource();
  }

  public setEditedValue(e: any, cellInfo: any): void {
    cellInfo.setValue(e.value);
  }

  private treatmentRoomsDatasource(): DataSource {
    return new DataSource({
      store: new CustomStore({
        key: 'Id',
        load: () => lastValueFrom(this.httpCodesService.getTreatmentRooms().pipe(takeUntil(this.destroyed))),
        update: (id, values) => {
          const toUpdate = this.treatmentRooms.items().find((_) => _.Id == id) as TreatmentRoom;

          setUpdateValues(values, toUpdate);

          toUpdate.OpenTime = setCorrectDateTimeForBackend(toUpdate.OpenTime);
          toUpdate.CloseTime = setCorrectDateTimeForBackend(toUpdate.CloseTime);

          return lastValueFrom(this.httpCodesService.updateTreatmentRoom(toUpdate).pipe(takeUntil(this.destroyed)));
        },
        remove: (id) => lastValueFrom(this.httpCodesService.deleteTreatmentRoom(id).pipe(takeUntil(this.destroyed))),
        insert: (treatmentRoom) => {
          treatmentRoom.OpenTime = setCorrectDateTimeForBackend(treatmentRoom.OpenTime);
          treatmentRoom.CloseTime = setCorrectDateTimeForBackend(treatmentRoom.CloseTime);

          return lastValueFrom(
            this.httpCodesService.insertTreatmentRoom(treatmentRoom).pipe(takeUntil(this.destroyed))
          );
        },
      }),
    });
  }
}
