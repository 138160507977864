import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { HttpCodeService } from '../services/http/http-code.service';
import { BaseSubscriptionComponent } from '../base/base.suscription.component';
import { takeUntil } from 'rxjs';
import { SubticketTypeEnum } from '../models/enums/subticket-type.enum';

@Component({
  selector: 'app-search-subticket-type',
  templateUrl: './search-subticket-type.component.html',
})
export class SearchSubticketTypeComponent extends BaseSubscriptionComponent implements OnInit {
  subticketTypes: any[];
  subticketTypesPopup: any;

  @Output() onSubticketSelected = new EventEmitter();
  constructor(private httpCodesService: HttpCodeService) {
    super();
  }

  ngOnInit() {
    this.httpCodesService
      .getSubticketTypes()
      .pipe(takeUntil(this.destroyed))
      .subscribe({
        next: (types) => {
          this.subticketTypes = types.filter((_) => _.Id != SubticketTypeEnum.Spa);
        },
        error: (e) => console.error(e),
      });
  }

  getSubticketTypePopupInstance(e) {
    this.subticketTypesPopup = e.component;
  }

  showSubticketTypePopup(e) {
    this.subticketTypesPopup.option('position', { my: 'left top', of: e });
    this.subticketTypesPopup.show();
    return false; // to avoid default behaviour
  }

  selectSubticketType(e) {
    this.onSubticketSelected.emit(e.itemData);
    this.subticketTypesPopup.hide();
  }
}
