import * as moment from 'moment';

export function setUpdateValues(valuesToSet: any, objectToSet: any) {
  if (!objectToSet) {
    return;
  }

  Object.entries(valuesToSet).forEach(([key, value]) => {
    if (key in objectToSet) {
      objectToSet[key] = value;
    }
  });
}

// TODO: Temporal Method for don't change old and bad logic. Review and refactor this logic, and review differencies btw Backend vs Frondend Time Zones
export function setCorrectDateTimeForBackend(date: Date): Date {
  const utcOffset = moment(date).utcOffset();
  return moment(date)
    .add('hours', utcOffset / 60.0)
    .toDate();
}
