import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { CreateUpdateReservation } from 'src/app/models/requests/create-update-reservation';
import { Reservation } from 'src/app/models/view-models/reservation';

import { Therapist } from 'src/app/models/view-models/therapist';

@Injectable({
  providedIn: 'root',
})
export class HttpReservationService {
  private baseUrl = `${this.serverUrl}Reservations`;

  constructor(private httpClient: HttpClient, @Inject('API_URL') public serverUrl: string) {}

  public getTherapistsByTreatmentType(treatmentTypeId: number): Observable<Therapist[]> {
    return this.httpClient.get<Therapist[]>(`${this.baseUrl}/GetTherapistsByTreatmentType/${treatmentTypeId}`);
  }

  public getReservationsByDate(date: string): Observable<Reservation[]> {
    return this.httpClient.get<Reservation[]>(`${this.baseUrl}/GetReservationsByDate/${date}`);
  }

  public insertReservation(reservation: CreateUpdateReservation): Observable<any> {
    return this.httpClient.post<any>(`${this.baseUrl}/InsertReservation`, reservation);
  }

  public updateReservation(reservation: CreateUpdateReservation): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUrl}/UpdateReservation`, reservation);
  }

  public deleteReservation(reservationId: number): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUrl}/DeleteReservation/${reservationId}`, null);
  }
}
