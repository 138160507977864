import { Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, lastValueFrom, takeUntil } from 'rxjs';

import { DxDataGridComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';

import { HttpSubticketService } from '../services/http/http-subticket.service';
import { BaseSubscriptionComponent } from '../base/base.suscription.component';
import { Subticket } from '../models/view-models/subticket';

@Component({
  templateUrl: './my-subtickets.component.html',
})
export class MySubticketsComponent extends BaseSubscriptionComponent implements OnInit {
  public subticketChanged = new BehaviorSubject(null);
  public modeChanged = new BehaviorSubject('view');

  public subtickets: DataSource;
  public subticketHistory: DataSource;
  public subticketHistoryVisible = false;
  public subticketDetailVisible = false;

  @ViewChild(DxDataGridComponent, { static: false }) private subticketsGrid: DxDataGridComponent;
  private selectedSubticketId: number;

  constructor(private httpSubticketService: HttpSubticketService) {
    super();
  }

  public ngOnInit(): void {
    this.subtickets = this.subticketsDatasource();
  }

  public showSubticketHistory(subticket: Subticket): void {
    this.selectedSubticketId = subticket.Id;
    this.subticketHistoryVisible = true;
    this.subticketHistory = this.subticketHistoryDatasource();
  }

  public showSubticketDetails(subticket: Subticket): void {
    this.modeChanged.next('view');
    this.subticketChanged.next(subticket);
    this.subticketDetailVisible = true;
  }

  public hideSubticketDetails(): void {
    this.subticketDetailVisible = false;
  }

  public subticketSaved(): void {
    this.subticketDetailVisible = false;
    this.subticketsGrid.instance.refresh();
  }

  private subticketsDatasource(): DataSource {
    return new DataSource({
      store: new CustomStore({
        key: 'Id',
        load: () => lastValueFrom(this.httpSubticketService.getMySubtickets().pipe(takeUntil(this.destroyed))),
      }),
    });
  }

  private subticketHistoryDatasource(): DataSource {
    return new DataSource({
      store: new CustomStore({
        key: 'Id',
        load: () =>
          lastValueFrom(
            this.httpSubticketService.getSubticketHistory(this.selectedSubticketId).pipe(takeUntil(this.destroyed))
          ),
      }),
    });
  }
}
