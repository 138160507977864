import { Component, OnInit, Input } from '@angular/core';
import { RemoteService } from '../../../services/remote.service';

@Component({
  selector: 'app-user-roles',
  templateUrl: './user-roles.component.html',
  styleUrls: ['./user-roles.component.scss']
})
export class UserRolesComponent implements OnInit {

    lookupRoles: any
    roles: any
    userId: string
    @Input() data
    constructor(private remote: RemoteService) { }

    async ngOnInit() {
        this.userId = this.data.data.Id
        this.lookupRoles = await this.remote.getRequest('Users/GetRoles')
        if (this.userId) {
            this.roles = await this.remote.getRequest('Users/GetRolesByUser/' + this.userId)
        }
        else {
            this.roles = []
        }
    }

    public changeParentValue() {
        // Simulate a change in the parent component
        this.data.setValue(Math.floor(Math.random() * 100000))
    }

}
