import { ApplicationUser } from './application-user';
import { Nullable } from './nullable-type';
import { SubticketStatus } from './subticket-status';
import { SubticketType } from './subticket-type';
import { Vendor } from './vendor';

export class Subticket {
  public Id: number;
  public DueDate: Date;
  public GuestName: Nullable<string>;
  public NumberOfGuests: Nullable<number>;
  public Notes: Nullable<string>;
  public VendorNotifiedDate: Nullable<Date>;
  public AsigneeId: Nullable<string>;
  public VendorId: Nullable<number>;
  public TicketId: Nullable<number>;
  public SourceText: string;
  public SubticketType: SubticketType;
  public SubticketStatus: SubticketStatus;
  public Asignee: ApplicationUser;
  public Vendor: Vendor;
}
