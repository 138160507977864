import { Component, OnInit, Input, Inject } from '@angular/core';
import { RemoteService } from '../../../services/remote.service';
import { CommonService } from '../../../services/common.service';

import { UUID } from 'angular2-uuid';
import { takeUntil } from 'rxjs';
import { HttpCodeService } from 'src/app/services/http/http-code.service';
import { BaseSubscriptionComponent } from 'src/app/base/base.suscription.component';

@Component({
  selector: 'app-vendor-documents',
  templateUrl: './vendor-documents.component.html',
})
export class VendorDocumentsComponent extends BaseSubscriptionComponent implements OnInit {
  vendorDocuments = [];

  vendorId: number;
  uuid: string;
  previewUrl: string;
  fileName: string;
  selectedId: number;
  canDownload = false;

  @Input() data;

  constructor(
    private remote: RemoteService,
    private common: CommonService,
    private httpCodesService: HttpCodeService,
    @Inject('API_URL') private serverUrl: string
  ) {
    super();
  }

  async ngOnInit() {
    this.vendorId = this.data.data.Id;
    if (this.vendorId) {
      this.vendorDocuments = await this.remote.getRequest('Vendors/GetDocuments/' + this.vendorId);
    } else {
      this.vendorDocuments = [];
    }
  }

  onInitNewRow(e) {
    e.data.VendorId = this.vendorId;
    this.uuid = UUID.UUID();
    e.data.UUID = this.uuid;
  }

  // Prepare an editor for file uploading
  onEditorPreparing(e) {
    if (e.parentType == 'dataRow' && e.dataField == 'File') {
      const uploadUrl = this.serverUrl + 'Codes/UploadFile/' + this.uuid + '/VENDOR_DOCUMENT';
      e.editorName = 'dxFileUploader';
      (e.editorOptions.uploadUrl = uploadUrl),
        (e.editorOptions.selectButtonText = 'Select File'),
        (e.editorOptions.uploadMode = 'instantly'),
        //e.editorOptions.accept = "application/pdf"
        (e.editorOptions.value = []);
      e.editorOptions.onValueChanged = (r) => {
        this.fileName = r.value[0].name;
        e.setValue(r.value[0].name);
      };
    }
  }

  public onUpdated(e) {
    e.data.FileName = this.fileName;

    // Simulate a change in the parent component
    this.data.setValue(Math.floor(Math.random() * 100000));
  }

  public onDeleted(e) {
    // Simulate a change in the parent component
    this.data.setValue(Math.floor(Math.random() * 100000));
  }

  download(row) {
    this.httpCodesService
      .getFileContentsByUUID(row.data.UUID)
      .pipe(takeUntil(this.destroyed))
      .subscribe({
        next: (fileContents) => {
          const blob = new Blob([this.common.base64ToArrayBuffer(fileContents.Contents)], { type: 'octet/stream' });
          this.previewUrl = window.URL.createObjectURL(blob);

          const link = document.createElement('a');
          link.download = fileContents.FileName;
          link.href = this.previewUrl;
          link.click();
        },
        error: (e) => console.error(e),
      });
  }
}
