import { Component, OnInit } from '@angular/core';
import { Hotel } from '../models/view-models/hotel';
import { ConfigService } from '../services/config.service';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { RemoteService } from '../services/remote.service';
import { Vendor } from '../models/view-models/vendor';

@Component({
  templateUrl: './telephone-book.component.html',
})
export class TelephoneBookComponent implements OnInit {
  public vendorsPhoneGrid: DataSource;
  private currentUserHotel: Hotel = null;

  constructor(private configService: ConfigService, private remoteService: RemoteService) {}

  public async ngOnInit(): Promise<void> {
    this.currentUserHotel = this.configService.getCurrentHotel();
    this.vendorsPhoneGrid = await this.getVendors();
  }

  private async getVendors(): Promise<DataSource> {
    const url = 'Vendors/GetVendorsPerHotel/' + this.currentUserHotel.Id;
    const allVendors = (await this.remoteService.getRequest(url)) as Vendor[];

    allVendors.forEach((currentVendor) => {
      currentVendor.SubticketTypesForGrid = currentVendor.SubticketTypes.map((_) => _.Name).join(', ');
    });

    return new DataSource({
      store: new CustomStore({
        key: 'Id',
        load: () => {
          return allVendors.filter((vendor) => vendor.Active);
        },
      }),
    });
  }
}
