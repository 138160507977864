import { Component, OnInit, Input } from '@angular/core';

import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';

import { SubticketTypeCodeEnum } from 'src/app/models/enums/subticket-type.enum';
import { HttpCodeService } from 'src/app/services/http/http-code.service';
import { lastValueFrom, takeUntil } from 'rxjs';
import { BaseSubscriptionComponent } from 'src/app/base/base.suscription.component';
import { EditorPreparingEvent } from 'devextreme/ui/data_grid';
import { SubticketTypeField } from 'src/app/models/view-models/subticket-type-field';
import { SubticketFieldTypeDescriptions, SubticketFieldTypeEnum } from 'src/app/models/enums/subticket-field-type.enum';
import { DropdownViewModel } from 'src/app/models/view-models/dropdown';
import { setUpdateValues } from 'src/app/helper/common-helper';

@Component({
  selector: 'app-subticket-type-fields',
  templateUrl: './subticket-type-fields.component.html',
})
export class SubticketTypeFieldsComponent extends BaseSubscriptionComponent implements OnInit {
  public subticketTypeFields: DataSource;
  public types: DropdownViewModel<string>[] = [];

  @Input() public subticketType: any;

  private allCurrentFields: SubticketTypeField[] = [];

  constructor(private httpCodesService: HttpCodeService) {
    super();
  }

  public ngOnInit(): void {
    this.subticketTypeFields = this.fieldsDatasource();

    // Set Types
    this.types = Object.values(SubticketFieldTypeEnum).map((type) => {
      const element: DropdownViewModel<string> = {
        Id: type,
        Description: SubticketFieldTypeDescriptions.get(type),
      };

      return element;
    });
  }

  public allowUpdatingDeleteAdd(): boolean {
    return (
      !this.subticketType ||
      !this.subticketType.Code ||
      (this.subticketType.Code !== SubticketTypeCodeEnum.Handover.value &&
        this.subticketType.Code !== SubticketTypeCodeEnum.Spa.value)
    );
  }

  public overrideOnValueChanged(opts: EditorPreparingEvent): void {
    // Logic for Mandatory Field
    if (opts.parentType === 'dataRow' && opts.dataField === 'Mandatory' && opts.row) {
      const data = opts.row.data as SubticketTypeField;

      // For Checkbox and File always not mandatory Field and disabled
      if (data.Type == SubticketFieldTypeEnum.CheckBox || data.Type == SubticketFieldTypeEnum.File) {
        opts.editorOptions.disabled = true;
        data.Mandatory = false;
      }
    }

    // Only one File Subticket Field for each subticket Type
    if (opts.parentType === 'dataRow' && opts.dataField === 'Type' && opts.row) {
      const data = opts.row.data as SubticketTypeField;
      if (data.Type === SubticketFieldTypeEnum.File) {
        return;
      }

      if (this.allCurrentFields.findIndex((_) => _.Type === SubticketFieldTypeEnum.File) >= 0) {
        opts.editorOptions.dataSource = this.types.filter((_) => _.Id !== SubticketFieldTypeEnum.File);
      }
    }
  }

  public fieldsDatasource(): DataSource {
    return new DataSource({
      store: new CustomStore({
        key: 'Id',
        load: async () => {
          this.allCurrentFields = await lastValueFrom(
            this.httpCodesService.getSubticketTypeFields(this.subticketType.Id).pipe(takeUntil(this.destroyed))
          );

          return this.allCurrentFields;
        },
        update: (id, values) => {
          const toUpdate = this.subticketTypeFields.items().find((_) => _.Id == id) as SubticketTypeField;

          setUpdateValues(values, toUpdate);
          toUpdate.SubticketTypeId = this.subticketType.Id;

          return lastValueFrom(
            this.httpCodesService.updateSubticketTypeField(toUpdate).pipe(takeUntil(this.destroyed))
          );
        },
        remove: (id) =>
          lastValueFrom(this.httpCodesService.deleteSubticketTypeField(id).pipe(takeUntil(this.destroyed))),

        insert: (subticketTypeField) => {
          subticketTypeField.SubticketTypeId = this.subticketType.Id;
          return lastValueFrom(
            this.httpCodesService.insertSubticketTypeField(subticketTypeField).pipe(takeUntil(this.destroyed))
          );
        },
      }),
    });
  }
}
