import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, lastValueFrom, takeUntil } from 'rxjs';

import notify from 'devextreme/ui/notify';

import { RemoteService } from '../services/remote.service';
import { SharedDataService } from '../services/shared-data.service';
import { ConfigService } from '../services/config.service';
import { SearchSubticketTypeComponent } from '../search-subticket-type/search-subticket-type.component';
import { HttpSubticketService } from '../services/http/http-subticket.service';
import { BaseSubscriptionComponent } from '../base/base.suscription.component';

@Component({
  selector: 'app-todo-assist',
  templateUrl: './todo-assist.component.html',
  styleUrls: ['./todo-assist.component.scss'],
})
export class TodoAssistComponent extends BaseSubscriptionComponent implements OnInit {
  hotelId: number;

  editor: any;
  editorContents: string;
  selection: any;

  subticketTypePopup: any;
  isSubticketTypePopupVisible = false;
  contextMenuItems: any[];

  isTodoAssistantVisible = false;

  ticket: any;
  subtickets: any[];
  currentSubticket: any;

  subticketChanged = new BehaviorSubject(null);
  modeChanged = new BehaviorSubject('view');

  @ViewChild(SearchSubticketTypeComponent, { static: false }) search: SearchSubticketTypeComponent;

  constructor(
    private shared: SharedDataService,
    private remote: RemoteService,
    private config: ConfigService,
    private router: Router,
    private httpSubticketService: HttpSubticketService
  ) {
    super();
  }

  async ngOnInit() {
    this.config.currentHotel.subscribe(async (h) => {
      this.hotelId = h != null ? h.Id : 0;
    });

    this.ticket = this.shared.storage;
    this.editorContents = this.ticket ? this.ticket.Message : null;

    this.subtickets = await lastValueFrom(
      this.httpSubticketService.getSubticketsByTicket(this.ticket.Id).pipe(takeUntil(this.destroyed))
    );
  }

  public editorCreated(e) {
    this.editor = e.component;
    setTimeout(() => {
      const theThis = this;
      this.editor.getQuillInstance().on('selection-change', (range, previousRange) => {
        theThis.selection = previousRange;
      });
    }, 0);
  }

  public createSubticketType(e) {
    const selection = this.selection;

    const selectedContents = this.editor.getQuillInstance().getContents(selection.index, selection.length);
    let selectedText = '';
    for (const e of selectedContents.ops) {
      if (typeof e.insert == 'string') selectedText += e.insert;
    }

    if (selection == null || selectedText.length == 0) {
      notify('Please, select some text', 'info');
      return;
    } else {
      this.editor.formatText(selection.index, selection.length, 'background', 'gainsboro');
      this.currentSubticket = {};
      this.currentSubticket.SubticketType = e;
      this.currentSubticket.SubticketTypeId = e.Id;
      this.currentSubticket.SourceText = selectedText;
      this.modeChanged.next('create');
      this.subticketChanged.next(this.currentSubticket);
      this.isTodoAssistantVisible = true;
    }
  }

  public async showSubticketDetail(data) {
    this.currentSubticket = data;
    this.modeChanged.next('view');
    this.subticketChanged.next(this.currentSubticket);
    this.isTodoAssistantVisible = true;
  }

  subticketSaved(e) {
    if (e == 'OK') {
      this.loadSubtickets();
      this.isTodoAssistantVisible = false;
    } else {
      notify(e, 'error');
    }
  }

  subticketDeleted(e) {
    this.loadSubtickets();
    this.isTodoAssistantVisible = false;
    notify('Subticket deleted');
  }

  subticketCanceled(e) {
    this.isTodoAssistantVisible = false;
  }

  saveAndBack() {
    const url = 'Tickets/SaveMessage/' + this.ticket.Id;
    this.remote
      .postRequest(url, this.editorContents)
      .then((r) => {
        notify('Ticket saved');
        this.router.navigate(['/inbox']);
      })
      .catch((e) => {
        notify(e, 'error');
      });
  }

  private async loadSubtickets() {
    this.subtickets = await lastValueFrom(
      this.httpSubticketService.getSubticketsByTicket(this.ticket.Id).pipe(takeUntil(this.destroyed))
    );
  }
}
