import { Enum } from './enum';

export enum SubticketTypeEnum {
  Handover = 10000000,
  Spa = 9999999,
}

export class SubticketTypeCodeEnum extends Enum<string> {
  public static readonly Handover = new Enum('HANDOVER');
  public static readonly Spa = new Enum('SPA');
}
