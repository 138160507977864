export enum AdminMenu {
  SubticketTypes = 'Subticket Types',
  Templates = 'Templates',
  Vendors = 'Vendors',
  Users = 'Users',
  HotelConfiguration = 'Hotel Configuration',
  TreatmentRooms = 'Treatment Rooms',
}

export enum SuperAdminMenu {
  TicketStatuses = 'Ticket Status',
  SubticketStatuses = 'Subticket Status',
  Priorities = 'Priorities',
  TreatmentTypes = 'Treatment Types',
  Therapists = 'Therapists',
}
