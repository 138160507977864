import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import notify from 'devextreme/ui/notify'

import { RemoteService } from './remote.service'
import { AuthenticationService } from './authentication.service'

@Injectable({
  providedIn: 'root'
})
export class CheckRolesService {
    currentActionChanged: BehaviorSubject<any> = new BehaviorSubject([])

    constructor(
        private remote: RemoteService,
        private auth: AuthenticationService
    ) { }

    async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let roles = await this.remote.getRequest('Users/GetAuthorizedRoles')
        let isAdmin = roles.some(r => r.RoleId == "ADMIN")
        if (!isAdmin) {
            notify("Unauthorized", "error")
            this.auth.logout()
        }
    }
}

@Injectable()
export class ActionService {


}

