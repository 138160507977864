import { Enum } from './enum';

export enum HandoverStatusEnum {
  New = 8,
  InProgress = 9,
  Resolved = 10,
  Acknowledged = 11,
}

export class HandoverStatusCodeEnum extends Enum<string> {
  public static readonly New = new Enum('NEW');
  public static readonly InProgress = new Enum('INPROGRESS');
  public static readonly Resolved = new Enum('RESOLVED');
  public static readonly Acknowledged = new Enum('ACKNOWLEDGED');
}

export class HandoverStatusCodeFilterEnum extends Enum<string> {
  public static readonly New = new Enum('New');
  public static readonly InProgress = new Enum('In Progress');
  public static readonly Acknowledged = new Enum('Acknowledged');
}
