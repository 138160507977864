import { Component, OnInit, Input } from '@angular/core';
import { RemoteService } from '../../../services/remote.service';

@Component({
  selector: 'app-vendor-subtickettypes',
  templateUrl: './vendor-subtickettypes.component.html',
  styleUrls: ['./vendor-subtickettypes.component.scss']
})
export class VendorSubtickettypesComponent implements OnInit {

    lookupSubticketTypes: any
    vendorSubticketTypes: any
    vendorId: number
    @Input() data
    constructor(private remote: RemoteService) { }

    async ngOnInit() {
        this.vendorId = this.data.data.Id
        this.lookupSubticketTypes = await this.remote.getRequest('Vendors/GetSubticketTypes')
        if (this.vendorId) {
            this.vendorSubticketTypes = await this.remote.getRequest('Vendors/GetSubticketTypes/' + this.vendorId)
        }
        else {
            this.vendorSubticketTypes = []
        }
    }

    public changeParentValue() {
        // Simulate a change in the parent component
        this.data.setValue(Math.floor(Math.random() * 100000))
    }
}
