import { Reservation } from './reservation';
import { SchedulerRow } from './scheduler-row';

export class ReservationScheduler extends SchedulerRow {
  // you can add N references of columns for N dxi-resource
  treatmentRoomId: number;

  // data of reservation
  reservation: Reservation;
}
