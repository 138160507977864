import { Component, OnInit } from '@angular/core';

import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';

import { ConfigService } from '../../services/config.service';
import { Hotel } from 'src/app/models/view-models/hotel';
import { SubticketTypeCodeEnum } from 'src/app/models/enums/subticket-type.enum';
import { SubticketType } from 'src/app/models/view-models/subticket-type';
import { BaseSubscriptionComponent } from 'src/app/base/base.suscription.component';
import { lastValueFrom, takeUntil } from 'rxjs';
import { HttpCodeService } from 'src/app/services/http/http-code.service';

@Component({
  selector: 'app-subticket-types',
  templateUrl: './subticket-types.component.html',
})
export class SubticketTypesComponent extends BaseSubscriptionComponent implements OnInit {
  public subticketTypes: any;

  private hotel: Hotel;

  constructor(private config: ConfigService, private httpCodesService: HttpCodeService) {
    super();
  }

  public ngOnInit(): void {
    this.subticketTypes = this.subticketTypesDatasource();
    this.hotel = this.config.getCurrentHotel();
  }

  public allowUpdatingOrDelete(event): boolean {
    return (
      !event ||
      ((event.row.data as SubticketType).Code !== SubticketTypeCodeEnum.Handover.value &&
        (event.row.data as SubticketType).Code !== SubticketTypeCodeEnum.Spa.value)
    );
  }

  public setEditedValue(e, cellInfo): void {
    cellInfo.setValue(e.value);
  }

  public subticketTypesDatasource() {
    return new DataSource({
      store: new CustomStore({
        key: 'Id',
        load: () => lastValueFrom(this.httpCodesService.getSubticketTypes().pipe(takeUntil(this.destroyed))),

        update: (id, subticketType) =>
          lastValueFrom(this.httpCodesService.updateSubticketType(id, subticketType).pipe(takeUntil(this.destroyed))),

        remove: (id) => lastValueFrom(this.httpCodesService.deleteSubticketType(id).pipe(takeUntil(this.destroyed))),

        insert: (subticketType) => {
          subticketType.hotelId = this.hotel.Id;
          return lastValueFrom(
            this.httpCodesService.insertSubticketType(subticketType).pipe(takeUntil(this.destroyed))
          );
        },
      }),
    });
  }
}
