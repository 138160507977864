import { Injectable } from '@angular/core';
import { Observable, finalize } from 'rxjs';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { StorageService } from '../services/storage.service';
import { LoaderService } from '../services/loader.service';
import { StoragePersistKeyEnum } from '../models/enums/storage-persist-key.enum';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(public loaderService: LoaderService, private storageService: StorageService) {
    this.storageService.setData(StoragePersistKeyEnum.RequestActive, 0);
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.addRequestActive();
    this.loaderService.show();

    return next.handle(req).pipe(
      finalize(() => {
        this.removeRequestActive();

        if (this.getRequestsActive() <= 0) {
          this.loaderService.hide();
        }
      })
    );
  }

  private getRequestsActive(): number {
    const requestsActives = this.storageService.getData<number>(StoragePersistKeyEnum.RequestActive);

    return requestsActives ? requestsActives : 0;
  }

  private addRequestActive(): void {
    let actives = this.getRequestsActive();
    actives++;

    this.storageService.setData(StoragePersistKeyEnum.RequestActive, actives);
  }

  private removeRequestActive(): void {
    let actives = this.getRequestsActive();
    actives--;

    this.storageService.setData(StoragePersistKeyEnum.RequestActive, actives);
  }
}
