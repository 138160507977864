import { Subticket } from './subticket';
import { TreatmentType } from './treatment-type';
import { TreatmentRoom } from './treatment-room';
import { Therapist } from './therapist';
import { Duration } from 'moment';

export class Reservation {
  public Id: number;
  public Subticket: Subticket;
  public Duration: Duration;
  public TreatmentType: TreatmentType;
  public TreatmentRoom: TreatmentRoom;
  public Therapist: Therapist;
}
