import { Routes } from '@angular/router';

import { CheckRolesService } from './services/check-roles.service';
import { AdminComponent } from './admin/admin.component';
import { InboxComponent } from './inbox/inbox.component';
import { MySubticketsComponent } from './my-subtickets/my-subtickets.component';
import { TodoAssistComponent } from './todo-assist/todo-assist.component';
import { ReplyGuestComponent } from './reply-guest/reply-guest.component';
import { HomeComponent } from './home/home.component';
import { GuestRequestsComponent } from './guest-requests/guest-requests.component';
import { ReportsComponent } from './reports/reports.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TelephoneBookComponent } from './telephone-book/telephone-book.component';
import { MyHandoverNotesComponent } from './my-handover-notes/my-handover-notes.component';
import { GuestRequestsItineraryComponent } from './guest-requests/itinerary/guest-requests-itinerary.component';
import { ReservationsComponent } from './reservations/reservations.component';

const defaultRoute = 'home';
export const ROUTES: Routes = [
  { path: '', redirectTo: defaultRoute, pathMatch: 'full' },

  { path: 'home', component: HomeComponent },
  { path: 'inbox', component: InboxComponent },
  { path: 'my-subtickets', component: MySubticketsComponent },
  { path: 'my-handover-notes', component: MyHandoverNotesComponent },
  { path: 'telephone-book', component: TelephoneBookComponent },
  { path: 'guest-requests', component: GuestRequestsComponent },
  { path: 'guest-requests-itinerary', component: GuestRequestsItineraryComponent },
  { path: 'todo-assist', component: TodoAssistComponent },
  { path: 'reply-guest', component: ReplyGuestComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'reports', component: ReportsComponent },
  { path: 'reservations', component: ReservationsComponent },
  { path: 'admin', component: AdminComponent, resolve: { action: CheckRolesService } },

  { path: '**', redirectTo: defaultRoute },
];
