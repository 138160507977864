import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import dxHtmlEditor, { ContentReadyEvent, ValueChangedEvent } from 'devextreme/ui/html_editor';
import { ItemReorderedEvent } from 'devextreme/ui/list';
import notify from 'devextreme/ui/notify';
import { lastValueFrom, takeUntil } from 'rxjs';
import { BaseSubscriptionComponent } from 'src/app/base/base.suscription.component';
import { HtmlEditor_Toolbar_Items } from 'src/app/base/constants';
import { GeneratePdfItineraryByText } from 'src/app/models/requests/generate-pdf-itinerary-by-text';
import { GenerateReplyOrItineraryText } from 'src/app/models/requests/generate-reply-or-itinerary-text';
import { SearchSubticketTemplates } from 'src/app/models/requests/search-subticket-templates';
import { SubticketTemplate } from 'src/app/models/view-models/subticket-template';
import { HttpSubticketService } from 'src/app/services/http/http-subticket.service';
import { SharedDataService } from 'src/app/services/shared-data.service';

@Component({
  templateUrl: './guest-requests-itinerary.component.html',
})
export class GuestRequestsItineraryComponent extends BaseSubscriptionComponent implements OnInit {
  public itineraryGenerated = '';
  public itineraryEditor: dxHtmlEditor;
  public toolbarItems = HtmlEditor_Toolbar_Items;
  public subticketsTemplates: SubticketTemplate[] = [];
  public selectedTemplates: SubticketTemplate[] = [];

  public pdfLoading = false;
  public showPdfPreview = false;
  public previewUrl = '';

  constructor(
    private router: Router,
    private httpSubticketService: HttpSubticketService,
    private sharedDataService: SharedDataService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.loadGridTemplates();
  }

  public editorInitialized(e: ContentReadyEvent): void {
    this.itineraryEditor = e.component;
  }

  public onItineraryChanged(e: ValueChangedEvent): void {
    this.itineraryGenerated = e.value;
  }

  public onSubticketsReordered(e: ItemReorderedEvent): void {
    const aux = this.subticketsTemplates[e.toIndex];
    this.subticketsTemplates[e.toIndex] = this.subticketsTemplates[e.fromIndex];
    this.subticketsTemplates[e.fromIndex] = aux;

    // Reorder selected subtickets
    const newSelectedSubtickets = [];
    this.subticketsTemplates.forEach((s) => {
      if (this.selectedTemplates.includes(s)) {
        newSelectedSubtickets.push(s);
      }
    });

    this.selectedTemplates = newSelectedSubtickets;
  }

  public backToMySubtickets(): void {
    this.router.navigate(['/guest-requests']);
    return;
  }

  public closeItineraryPreview(): void {
    this.showPdfPreview = false;
    this.pdfLoading = false;
    this.previewUrl = '';
  }

  public async printItinerary(): Promise<void> {
    if (this.selectedTemplates.length == 0) {
      notify('Please, select some requests');
      return;
    }

    this.itineraryGenerated = await this.generateItineraryText(this.selectedTemplates);

    const generateItineraryRequest: GeneratePdfItineraryByText = new GeneratePdfItineraryByText();
    generateItineraryRequest.ItineraryGenerated = this.itineraryGenerated;

    this.showPdfPreview = true;
    this.pdfLoading = true;

    this.httpSubticketService
      .generatePdfItineraryByText(generateItineraryRequest)
      .pipe(takeUntil(this.destroyed))
      .subscribe({
        next: (byteArray) => {
          const pdfContents = byteArray;
          this.previewUrl = 'data:application/pdf;base64,' + pdfContents;
          this.pdfLoading = false;
        },
        error: (error) => {
          notify(error, 'error');
          this.pdfLoading = false;
          this.previewUrl = '';
        },
        complete: () => console.info('complete'),
      });
  }

  public async generateItinerary(): Promise<void> {
    if (this.selectedTemplates.length == 0) {
      notify('Please, select some requests');
      return;
    }

    this.itineraryGenerated = await this.generateItineraryText(this.selectedTemplates);
  }

  private async generateItineraryText(subtickets: SubticketTemplate[]): Promise<string> {
    return await lastValueFrom(
      this.httpSubticketService
        .generateReplyOrItineraryText(subtickets as GenerateReplyOrItineraryText[])
        .pipe(takeUntil(this.destroyed))
    );
  }

  private loadGridTemplates(): void {
    this.sharedDataService.sharedData.pipe(takeUntil(this.destroyed)).subscribe((subticketIds) => {
      if (subticketIds && subticketIds.length > 0) {
        const searchFilter = new SearchSubticketTemplates();
        searchFilter.SubticketIds = subticketIds;

        this.httpSubticketService
          .searchSubticketTemplatesBySubticketIds(searchFilter)
          .pipe(takeUntil(this.destroyed))
          .subscribe({
            next: (subtickets) => (this.subticketsTemplates = subtickets),
            error: (e) => console.error(e),
          });
      }
    });
  }
}
