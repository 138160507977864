import { Component, OnInit, ViewChild } from '@angular/core';

import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';

import { UserRolesComponent } from './user-roles/user-roles.component';
import { RemoteService } from '../../services/remote.service';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  users: DataSource;
  hotelId: number;
  selectedId: number;
  lookupHotels: any[];

  saving = false;

  @ViewChild(UserRolesComponent, { static: false }) public userRoles: UserRolesComponent;
  constructor(private remote: RemoteService, private config: ConfigService) {}

  async ngOnInit() {
    this.config.currentHotel.subscribe(async (h) => {
      if (h) {
        this.hotelId = h.Id;
        this.users = this.usersStore();
      }
    });
  }

  onEditorPreparing(e) {
    if (e.parentType == 'dataRow' && e.dataField == 'PasswordHash') {
      e.editorOptions.mode = 'password';
    }
  }

  onInitNewRow(e) {
    e.data.PasswordHash = null;
    e.data.Enabled = true;
  }

  usersStore() {
    return new DataSource({
      store: new CustomStore({
        key: 'Id',
        load: () => {
          const url = 'Users/GetUsersPerHotel/' + this.hotelId;
          return this.remote.getRequest(url);
        },
        insert: (data) => {
          data.HotelId = this.hotelId;
          data.Roles = this.userRoles.roles;
          console.log(this.userRoles);
          const url = 'Users/Insert';
          return this.remote.postRequest(url, data);
        },
        remove: (Id) => {
          const url = 'Users/Delete/' + Id;
          return this.remote.postRequest(url, null);
        },
        update: (Id, data) => {
          data.Id = Id;
          data.HotelId = this.hotelId;
          console.log(this.userRoles);
          data.Roles = this.userRoles.roles;
          const url = 'Users/Update';
          return this.remote.postRequest(url, data);
        },
      }),
    });
  }
}
