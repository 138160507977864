import { Component, OnInit } from '@angular/core';

import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';

import { SubticketStatus } from 'src/app/models/view-models/subticket-status';
import { HandoverStatusCodeEnum } from 'src/app/models/enums/handover-note-status.enum';
import { BaseSubscriptionComponent } from 'src/app/base/base.suscription.component';
import { HttpCodeService } from 'src/app/services/http/http-code.service';
import { lastValueFrom, takeUntil } from 'rxjs';
import { setUpdateValues } from 'src/app/helper/common-helper';

@Component({
  selector: 'app-subticket-statuses',
  templateUrl: './subticket-statuses.component.html',
})
export class SubticketStatusesComponent extends BaseSubscriptionComponent implements OnInit {
  public statuses: DataSource;

  constructor(private httpCodesService: HttpCodeService) {
    super();
  }

  public ngOnInit(): void {
    this.statuses = this.statusesDatasource();
  }

  public allowUpdatingOrDelete(event): boolean {
    const subticketStatusesForHandover: string[] = [
      HandoverStatusCodeEnum.New.value,
      HandoverStatusCodeEnum.InProgress.value,
      HandoverStatusCodeEnum.Acknowledged.value,
      HandoverStatusCodeEnum.Resolved.value,
    ];

    return !event || !subticketStatusesForHandover.includes((event.row.data as SubticketStatus).Code);
  }

  public setEditedValue(e, cellInfo): void {
    cellInfo.setValue(e.value);
  }

  public statusesDatasource(): DataSource {
    return new DataSource({
      store: new CustomStore({
        key: 'Id',
        load: () => lastValueFrom(this.httpCodesService.getSubticketStatuses().pipe(takeUntil(this.destroyed))),
        update: (id, values) => {
          const toUpdate = this.statuses.items().find((_) => _.Id == id);

          setUpdateValues(values, toUpdate);

          return lastValueFrom(this.httpCodesService.updateSubticketStatus(toUpdate).pipe(takeUntil(this.destroyed)));
        },
        remove: (id) => lastValueFrom(this.httpCodesService.deleteSubticketStatus(id).pipe(takeUntil(this.destroyed))),
        insert: (subticketStatus) =>
          lastValueFrom(this.httpCodesService.insertSubticketStatus(subticketStatus).pipe(takeUntil(this.destroyed))),
      }),
    });
  }
}
