import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import * as moment from 'moment';
import * as XLSX from 'xlsx';

import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import notify from 'devextreme/ui/notify';
import 'devextreme/integration/jquery';

import { RemoteService } from '../services/remote.service';
import { SharedDataService } from '../services/shared-data.service';
import { ConfigService } from '../services/config.service';
import { AuthenticationService } from '../services/authentication.service';
import { HttpCodeService } from '../services/http/http-code.service';
import { lastValueFrom, takeUntil } from 'rxjs';
import { BaseSubscriptionComponent } from '../base/base.suscription.component';
import { Hotel } from '../models/view-models/hotel';
import { HttpUserService } from '../services/http/http-user.service';
import { TicketStatusCodeEnum } from '../models/enums/ticket-status.enum';

@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.scss'],
})
export class InboxComponent extends BaseSubscriptionComponent implements OnInit, OnDestroy {
  agents: any[] = [];

  selectFilterStatus: any;
  statuses: any[];
  selectedStatuses: any[];
  selectStatusesReady = false;
  selectFilterAgent: any;
  selectFilterSource: any;
  selectOrderBy = ['New Tickets First', 'Old Tickets First', 'Status', 'Agent', 'Source', 'Priority'];
  initialized = false;

  filterOrderData: any = {};
  filterText = '';

  Tickets: any[];
  selectedTickets: any[] = [];

  // Tickets paging
  page = 1;
  pageSize = 6;

  loadingTickets = true;

  assignAgentVisible = false;
  selectedAgent: any;

  priorities: any[];
  assignPriorityVisible = false;

  reloadInterval: NodeJS.Timer = null;

  private hotel: Hotel;

  constructor(
    private remote: RemoteService,
    private router: Router,
    private shared: SharedDataService,
    private config: ConfigService,
    private auth: AuthenticationService,
    private httpCodesService: HttpCodeService,
    private httpUserService: HttpUserService
  ) {
    super();
    this.getTickets = this.getTickets.bind(this);
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();

    if (this.reloadInterval) {
      clearInterval(this.reloadInterval);
    }
  }

  async ngOnInit() {
    this.hotel = this.config.getCurrentHotel();

    this.agents = await lastValueFrom(this.httpUserService.getAgents().pipe(takeUntil(this.destroyed)));

    this.priorities = await lastValueFrom(this.httpCodesService.getPriorities().pipe(takeUntil(this.destroyed)));

    this.priorities = this.priorities.filter((p) => p.Code != 'UNASSIGNED');

    this.statuses = await lastValueFrom(this.httpCodesService.getTicketStatuses().pipe(takeUntil(this.destroyed)));

    this.selectedStatuses = this.statuses.filter(
      (p) => p.Code != TicketStatusCodeEnum.Closed.value && p.Code != TicketStatusCodeEnum.Cancelled.value
    );

    this.filterOrderData.dateIni = moment().subtract(60, 'days').toDate();
    this.filterOrderData.dateEnd = moment().toDate();
    this.filterOrderData.orderBy = this.selectOrderBy[0];
    this.filterText = '';

    this.selectFilterStatus = this.selectFilterStatusValue();
    this.selectFilterAgent = this.selectFilterAgentValue();
    this.selectFilterSource = this.selectFilterSourceValue();

    this.initialized = true;
    this.getTickets();

    // Reload tickets
    this.reloadInterval = setInterval(() => {
      if (this.auth.authenticatedChanged.getValue() == true) {
        this.getTickets();
      }
    }, 60000);
  }

  public getPicture(picture) {
    if (picture == null) return 'assets/images/default-picture.jpg';
    return picture;
  }

  public getTime(received) {
    const days = moment.duration(moment().diff(moment(received))).as('days');
    const hours = moment.duration(moment().diff(moment(received))).as('hours');
    const minutes = moment.duration(moment().diff(moment(received))).as('minutes');

    if (days >= 1) return Math.trunc(days) + ' days ago';
    else if (Math.trunc(days) == 0 && hours >= 1) return Math.trunc(hours) + ' hours ago';
    else return Math.trunc(minutes) + ' minutes ago';
  }

  getStatusDue(status, dueDate) {
    const days = moment.duration(moment(dueDate).diff(moment())).asDays();

    if (days >= 1) return { color: 'green', message: 'Due in ' + Math.trunc(days) + ' days' };
    else if (days > 0) return { color: 'orange', message: 'Due today' };
    else return { color: 'red', message: 'PAST DUE' };
  }

  isTicketSelected(ticket) {
    return this.selectedTickets.some((t) => t.Id == ticket.Id);
  }

  rowSelected(e, ticket) {
    if (e.value == true) {
      if (this.selectedTickets.length + 1 > 5) {
        e.component.option('value', false);
        notify('You cannot do bulk operations with more than 5 tickets', 'error');
      } else this.selectedTickets.push(ticket);
    } else {
      this.selectedTickets = this.selectedTickets.filter((m) => m.Id != ticket.Id);
    }
  }

  searchText(filterText) {
    this.filterText = filterText;
    this.getTickets();
  }

  getTickets() {
    // Execute only if everything has been initialized
    if (this.initialized == true) {
      this.loadingTickets = true;

      const url = 'Tickets/GetTicketsFiltered/' + this.hotel.Id + '/';
      const filter = {
        dateIni: moment(this.filterOrderData.dateIni).format('YYYY-MM-DD'),
        dateEnd: moment(this.filterOrderData.dateEnd).format('YYYY-MM-DD'),
        agentId: this.filterOrderData.filterAgent ? this.filterOrderData.filterAgent : -1,
        sourceId: this.filterOrderData.filterSource ? this.filterOrderData.filterSource : -1,
        filterText: this.filterText,
        statuses: this.selectedStatuses.map((s) => s.Id),
        orderBy: this.filterOrderData.orderBy,
      };

      this.remote
        .postRequest(url, filter)
        .then((t) => {
          this.Tickets = t;
          this.selectedTickets = this.selectedTickets.filter((st) => this.Tickets.findIndex((t) => t.Id == st.Id) >= 0);
        })
        .catch((e) => {
          notify(e, 'error');
        })
        .finally(() => {
          this.loadingTickets = false;
        });
    }
  }

  public assignPriorityShow() {
    if (this.selectedTickets.length == 0) {
      notify('Please, select some tickets');
      return;
    }

    this.assignPriorityVisible = true;
  }

  public async assignPriority(priority) {
    if (priority) {
      const url = 'Tickets/AssignPriority/' + priority.Id;
      this.loadingTickets = true;
      await this.remote.postRequest(url, this.selectedTickets);
      this.loadingTickets = false;
      this.getTickets();
      notify('The selected Tickets have been given a priority ' + priority.Name);
    }
    this.assignPriorityVisible = false;
  }

  public assignAgentShow() {
    if (this.selectedTickets.length == 0) {
      notify('Please, select some tickets');
      return;
    }

    this.selectedAgent = null;
    this.assignAgentVisible = true;
  }

  public selectAgent(e) {
    this.selectedAgent = e.itemData.Id;
  }

  public async assignAgent() {
    if (this.selectedAgent) {
      const url = 'Tickets/AssignAgent/' + this.selectedAgent;
      this.loadingTickets = true;
      await this.remote.postRequest(url, this.selectedTickets);
      this.loadingTickets = false;
      this.getTickets();
      const agent = await this.remote.getRequest('Users/GetUserDataById/' + this.selectedAgent);
      notify('The selected Tickets have been assigned to ' + agent.Name);
    }
    this.assignAgentVisible = false;
  }

  async pickup() {
    if (this.selectedTickets.length == 0) {
      notify('Please, select some tickets');
      return;
    }

    const url = 'Tickets/Pickup';
    this.loadingTickets = true;
    await this.remote.postRequest(url, this.selectedTickets);
    this.loadingTickets = false;
    this.getTickets();
    notify('The selected Tickets have been assigned to you');
  }

  async merge() {
    if (this.selectedTickets.length < 2) {
      notify('Please, select at least two tickets');
      return;
    }

    const url = 'Tickets/Merge';
    this.loadingTickets = true;
    await this.remote.postRequest(url, this.selectedTickets);
    this.loadingTickets = false;
    this.getTickets();
    notify('The selected tickets have been merged');
  }

  async close() {
    if (this.selectedTickets.length == 0) {
      notify('Please, select the tickets to close');
      return;
    }

    const url = 'Tickets/Close';
    this.loadingTickets = true;
    await this.remote.postRequest(url, this.selectedTickets);
    this.loadingTickets = false;
    this.getTickets();
    notify('The selected tickets have been closed');
  }

  async cancel() {
    if (this.selectedTickets.length == 0) {
      notify('Please, select the tickets to cancel');
      return;
    }

    const url = 'Tickets/Cancel';
    this.loadingTickets = true;
    await this.remote.postRequest(url, this.selectedTickets);
    this.loadingTickets = false;
    this.getTickets();
    notify('The selected tickets have been canceled');
  }

  exportEXCEL() {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.Tickets);
    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const myBlob = new Blob([excelBuffer], { type: 'EXCEL_TYPE' });
    const url = window.URL.createObjectURL(myBlob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'Tickets.xlsx'; //aqui el nombre
    link.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
    }, 10);
  }

  openTodoAssistant(ticket) {
    this.shared.storage = ticket;
    this.router.navigateByUrl('/todo-assist');
  }

  openReplyGuest(ticket) {
    this.shared.storage = ticket;
    this.router.navigateByUrl('/reply-guest');
  }

  public selectFilterStatusValue() {
    const theThis = this;

    return {
      valueExpr: 'Id',
      width: 200,
      contentTemplate: function (e) {
        const $dxList = $('<div>').dxList({
          dataSource: theThis.statuses,
          keyExpr: 'Id',
          selectionMode: 'multiple',
          showSelectionControls: true,
          itemTemplate: function (data, _, element) {
            element.append($('<p>').text(data.Name).css('margin', 0));
          },

          // Initial selection
          onContentReady: (e) => {
            const items = e.component.getDataSource().items();
            for (let i = 0; i < items.length; i++) {
              if (theThis.selectedStatuses.includes(items[i])) {
                e.component.selectItem(i);
              }
            }
            theThis.selectStatusesReady = true;
          },

          // Selection change
          onSelectionChanged: (e) => {
            if (theThis.selectStatusesReady) {
              const items = e.component.getDataSource().items();
              theThis.selectedStatuses = [];
              for (let i = 0; i < items.length; i++) {
                if (e.component.isItemSelected(i)) {
                  theThis.selectedStatuses.push(items[i]);
                }
              }
              if (theThis.selectStatusesReady) {
                theThis.getTickets();
              }
            }
          },
        });

        return $dxList;
      },
    };
  }

  public selectFilterAgentValue() {
    const allValue = { Id: -1, Name: 'All' };
    return {
      dataSource: new DataSource({
        store: new CustomStore({
          byKey: () => Promise.resolve(allValue),
          key: 'Id',
          load: async () => {
            const items = await lastValueFrom(this.httpUserService.getAgents().pipe(takeUntil(this.destroyed)));

            items.push({ Id: -1, Name: 'All' });
            return Promise.resolve(items);
          },
        }),
      }),
      displayExpr: 'Name',
      valueExpr: 'Id',
      value: -1,
      popupWidth: 300,
      closeOnOutsideClick: true,
    };
  }

  public selectFilterSourceValue() {
    const allValue = { Id: -1, Name: 'All' };
    return {
      dataSource: new DataSource({
        store: new CustomStore({
          byKey: (key) => Promise.resolve(allValue),
          key: 'Id',
          load: async () => {
            const items = await lastValueFrom(this.httpCodesService.getSources().pipe(takeUntil(this.destroyed))).then(
              (sources) => {
                return sources;
              }
            );

            items.push({ Id: -1, Name: 'All' });
            return Promise.resolve(items);
          },
        }),
      }),
      displayExpr: 'Name',
      valueExpr: 'Id',
      value: -1,
    };
  }
}
